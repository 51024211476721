<template>
  <div>
    <component v-bind:is="layout"></component>
  </div>
</template>

<script>
import Guest from "./views/components/layouts/Guest";
import Patient from "./views/components/layouts/Patient";
import None from "./views/components/layouts/None";

export default {
  components: {
    'guest-layout': Guest,
    'patient-layout': Patient,
    'none-layout': None,
  },
  computed: {
    layout () {
      return this.$route.meta.layout ?? 'none-layout'
    }
  },
}
</script>

<style>
@import'~bootstrap/dist/css/bootstrap.css';
@import'~@mdi/font/css/materialdesignicons.min.css';
@import'~@iconscout/unicons/css/line.css';
@import "./assets/css/style.css";
@import "./assets/css/colors/default.css";
</style>
