<template>
  <div class="row">
    <div class="col-lg-12 mb-4">
      <div class="row">
        <div class="col-lg-4 mt-2">
          <div class="d-grid gap-2">
            <button type="button" class="btn btn-success" title="Novo paciente"
                    data-bs-target="#ModalPatient" data-bs-toggle="modal"
                    @click="editing = false; currentPatient = null; formPatient = {}">
              <i class="fas fa-plus icons"></i> Novo
            </button>
          </div>
        </div>
        <div class="col-lg-8 mt-2">
          <div class="form-icon position-relative mt-sm-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-search fea icon-sm icons">
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
            <input name="search" id="search" class="form-control ps-5" placeholder="Pesquisar paciente" v-model="search">
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 mb-1" v-for="(patient, key) in patients" :class="key > 2 ? 'mt-2' : ''">
      <div class="company-list card border-0 rounded shadow bg-white">
        <div class="text-center py-3 border-bottom">
          <img :src="url(patient.patient_photo)" v-if="patient.patient_photo"
               class="avatar avatar-small mx-auto rounded-circle d-block mb-3" alt="Foto">
          <p style="line-height: 2.1" v-else class="avatar avatar-medium rounded-circle shadow d-block mx-auto">
                <span style="font-size: 55px">
                  {{ initialsName(patient.patient_name) }}
                </span>
          </p>

          <a href="#" @click.prevent="" class="text-dark h5 name">
            {{ patient.patient_name }}
          </a>

          <p class="text-muted mt-1 mb-0">{{ maskit(patient.patient_cpf) }}</p>
        </div>

        <div class="p-4">
          <div class="row text-center">
            <div class="col-6">
              <button class="btn btn-soft-primary" data-bs-target="#ModalPatient" data-bs-toggle="modal"
                      @click="currentPatient = patient; editing = true; formPatient = patient;" title="Editar paciente">
                <i class="fa fa-edit"></i>
              </button>
            </div>
            <div class="col-6">
              <button class="btn btn-soft-danger" data-bs-target="#ConfirmDelete" data-bs-toggle="modal"
                      v-if="!patient.main"
                      @click="currentPatient = patient" title="Remover paciente">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="ModalPatient" tabindex="1" aria-hidden="true">
    <div class="modal-dialog">
      <form method="post" @submit.prevent="savePatient" ref="formPatient">
        <div class="modal-content rounded shadow border-0">
          <div class="modal-header border-bottom">
            <h5 class="modal-title">{{ editing ? 'Editar' : 'Novo' }} paciente</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">

                <div class="alert alert-success" role="alert" v-if="msgPatientSuccess">
                  {{ msgPatientSuccess }}
                </div>

                <div class="alert alert-danger" role="alert" v-if="msgPatientError">
                  {{ msgPatientError }}
                </div>

                <div class="mb-3">
                  <label class="form-label" for="patient_name">Nome <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-user fea icon-sm icons">
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                    <input name="patient_name" id="patient_name" type="text" v-model="formPatient.patient_name"
                           :class="errors.patient_name ? 'is-invalid' : ''" required
                           class="form-control ps-5" placeholder="Nome :">
                    <div class="invalid-feedback d-block" v-if="errors.patient_name">
                      {{ errors.patient_name.join('\n') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label" for="patient_cpf">CPF <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-mail fea icon-sm icons">
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                      <polyline points="22,6 12,13 2,6"></polyline>
                    </svg>
                    <input name="patient_cpf" id="patient_cpf" type="tel" v-model="formPatient.patient_cpf"
                           :class="errors.patient_cpf ? 'is-invalid' : ''" v-mask="'###.###.###-##'"
                           class="form-control ps-5" placeholder="CPF :" required>
                    <div class="invalid-feedback d-block" v-if="errors.patient_cpf">
                      {{ errors.patient_cpf.join('\n') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label" for="patient_birthdate">Data Nascimento <span
                      class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-mail fea icon-sm icons">
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                      <polyline points="22,6 12,13 2,6"></polyline>
                    </svg>
                    <input name="patient_birthdate" id="patient_birthdate" type="tel"
                           v-model="formPatient.patient_birthdate" required
                           :class="errors.patient_birthdate ? 'is-invalid' : ''" v-mask="'##/##/####'"
                           class="form-control ps-5" placeholder="Data de nascimento :">
                    <div class="invalid-feedback d-block" v-if="errors.patient_birthdate">
                      {{ errors.patient_birthdate.join('\n') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Foto</label>
                  <div class="form-icon position-relative">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-camera fea icon-sm icons">
                      <path
                          d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
                      <circle cx="12" cy="13" r="4"></circle>
                    </svg>
                    <input name="photo" id="photo" type="file" class="form-control ps-5" placeholder="Sua foto :"
                           accept="image/*" :class="errors.patient_photo ? 'is-invalid' : ''" ref="photo">
                    <div class="invalid-feedback d-block" v-if="errors.patient_photo">
                      {{ errors.patient_photo.join('\n') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label" for="observations">Observação</label>
                  <div class="form-icon position-relative">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-message-circle fea icon-sm icons">
                      <path
                          d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
                    </svg>
                    <textarea name="observations" id="observations" rows="4"
                              :class="errors.observations ? 'is-invalid' : ''" v-model="formPatient.observations"
                              class="form-control ps-5" placeholder="Observação do paciente :"></textarea>
                    <div class="invalid-feedback d-block" v-if="errors.observations">
                      {{ errors.observations.join('\n') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" :disabled="submitted" class="btn btn-outline-primary">
              Salvar
              <svg v-if="submitted" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="spinner"
                   class="local-spin svg-inline--fa fa-spinner fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 512 512">
                <path fill="currentColor"
                      d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"></path>
              </svg>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal fade" id="ConfirmDelete" tabindex="1" aria-hidden="true">
    <div class="modal-dialog">
      <form method="post" @submit.prevent="removePatient" ref="formPatient">
        <div class="modal-content rounded shadow border-0">
          <div class="modal-header border-bottom">
            <h5 class="modal-title">Remover Paciente</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>
              Tem certeza que deseja remover esse paciente?
            </p>
          </div>
          <div class="modal-footer">
            <button type="submit" :disabled="submitted" class="btn btn-outline-primary">
              Remover
              <svg v-if="submitted" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="spinner"
                   class="local-spin svg-inline--fa fa-spinner fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 512 512">
                <path fill="currentColor"
                      d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"></path>
              </svg>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mask} from "vue-the-mask";
import http from "@/helpers/http";
import strMask from "@/helpers/strMask";
import str from "@/helpers/str";
import Alert from "@/helpers/alert";
import date from "@/helpers/date";

export default {
  name: "Patient",
  directives: {
    mask,
  },
  data() {
    return {
      search: '',
      submitted: false,
      editing: false,
      patientsList: [],
      currentPatient: {},
      formPatient: {
        patient_name: '',
        patient_cpf: '',
        patient_birthdate: '',
        observations: '',
      },
      errors: [],
      msgPatientSuccess: '',
      msgPatientError: '',
    }
  },
  watch: {
    currentPatient: function (newValue) {
      if (newValue?.patient_birthdate)
        newValue.patient_birthdate = newValue.patient_birthdate_br;
    }
  },
  computed: {
    userAuth() {
      return this.$store.state.auth.user;
    },
    patients() {
      if (this.search === '')
        return this.patientsList;

      return this.patientsList.filter(patient => {
        return patient.patient_name.toLowerCase().includes(this.search.toLowerCase())
      });
    },
  },
  mounted() {
    this.loadPatients();
  },
  methods: {
      url(path) {
          return str.url(path)
      },
    maskit(value) {
      return strMask.it(value);
    },
    initialsName(name) {
      return str.initialsName(name)
    },
    loadPatients() {
      http.get(`/users/${this.userAuth.id_user}/patients`).then((response) => {
        this.patientsList = response.data;
      });
    },
    removePatient() {
      let modalConfirmDelete = bootstrap.Modal.getInstance(document.getElementById('ConfirmDelete'));
      modalConfirmDelete.toggle();

      http.delete(`/users/${this.userAuth.id_user}/patients/${this.currentPatient.id_patient}`).then(() => {
        this.loadPatients();
      }).catch((e) => Alert.error(e.response.data))
    },
    savePatient() {
      this.submitted = true;
      let userAuth = this.$store.state.auth.user;
      this.errors = [];
      this.msgPatientError = '';

      let url = `/users/${userAuth.id_user}/patients`;
      let method = 'post';
      if (this.editing) {
        url = `/users/${userAuth.id_user}/patients/${this.currentPatient.id_patient}`;
        method = 'put';
      }

      const form = new FormData();
      form.append('_method', method);
      form.append('patient_name', this.formPatient.patient_name);
      form.append('patient_cpf', this.formPatient.patient_cpf);
      form.append('patient_birthdate', this.formPatient.patient_birthdate);
      form.append('observations', this.formPatient.observations);

      if (this.$refs.photo.files.length > 0)
        form.append('patient_photo', this.$refs.photo.files[0]);

      http({
        method: 'post',
        url: url,
        data: form
      }).then((_) => {
        this.msgPatientSuccess = 'Paciente cadastrado com sucesso.';
        this.loadPatients();
        this.formPatient = {
          patient_name: '',
          patient_cpf: '',
          patient_birthdate: '',
          observations: '',
        };
      }).catch((error) => {
        let response = error.response;
        if (response.data?.errors) {
          this.errors = response.data.errors;
        } else {
          this.msgPatientError = response.data;
        }
      }).finally(() => {
        this.submitted = false;
      });

    }
  }
}
</script>

<style scoped type="text/scss">
@import '~sweetalert2/dist/sweetalert2.css';
</style>