import http from "../helpers/http";
import db from "../helpers/db";

export default {
    namespaced: true,
    state: {
        user: db.user,
        token: db.token,
    },
    mutations: {
        setUser(state, user) {
            state.user = db.storeUser(user);
        },
        setToken(state, token) {
            state.token = db.storeToken(token);
        },
    },
    actions: {
        loginUsingToken(context, token) {
            if (!context.state.token)
                context.commit('setToken', token);

            return http.get('/users/me').then(response => {
                context.commit('setUser', response.data);
                return response;
            });
        },
        login(context, auth) {
            return http.post('/auth/login', {user_email: auth.username, user_password: auth.password}).then(response => {
                context.commit('setUser', response.data.user);
                context.commit('setToken', response.data.token);
                return response;
            });
        },
        recoverPassword(context, username) {
            return http.post('/auth/password/link', {cpf: username});
        },
        recoverPasswordCode(context, params) {
            return http.post('/auth/password/code', {cpf: params.username, origin: params.origin});
        },
        validateCode(context, data) {
            return http.post(`/auth/password/validate-code`, {user_cpf: data.user_cpf, code: data.code});
        },
        logout(context) {
            return http.post('/auth/logout').then(() => {
                context.commit('setUser', null);
                context.commit('setToken', null);
            });
        },
    },
    getters: {
        isAuth(state) {
            return state.user  != null &&
                   state.token != null;
        }
    },
}