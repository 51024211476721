<template>
  <div>
    <div v-if="urlExamPreparation" class="mt-6">
      <iframe :src="url(urlExamPreparation)"
              class="w-100 min-vh-100 mt-6"
              style="border: none; margin-top: 100px"></iframe>
    </div>
    <section v-else class="bg-half-170 d-table w-100 home-wrapper overflow-hidden"
             style="padding-bottom: 0; padding-top: 100px">
      <div class="container-fluid">
        <div class="row m-3">
          <div class="col-lg-4 d-none d-lg-block">
            <img :src="require('@/assets/images/mammography-cuate.svg')"
                 alt="Doctor" class="rounded img-fluid mx-auto d-block w-75">
          </div>

          <div class="col-lg-8 pt-lg-5" style="padding-bottom: 0;">
            <p class="h4">
              <b>Veja aqui o seu preparo para seu exame</b>
            </p>
            <p>Siga abaixo as instruções de preparo:</p>
            <div class="row text-center mt-6">
                <p>{{ examPreparation }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>

import http from "../../helpers/http";
import VuePdfEmbed from 'vue-pdf-embed';
import str from "@/helpers/str";

export default {
  name: "ExamPreparation",
  components: {
    VuePdfEmbed
  },
  mounted() {
    let params = atob(this.$route.params.infos)
    let [placeId, specId] = params.split('@');

    this.placeId = placeId;
    this.specId = specId;

    this.getExamPreparation();
  },
  data() {
    return {
      placeId: '',
      specId: '',
      urlExamPreparation: '',
      examPreparation: '',
    }
  },
  methods: {
    url(path) {
      return str.url(path)
    },
    getExamPreparation() {
      http.get(`/places/${this.placeId}/specialties/${this.specId}/exam-preparation`).then(response => {
        this.urlExamPreparation = response.data.url_exam_preparation;
        this.examPreparation = response.data.exam_preparation;
      }).catch(error => {
        console.log(error);
      });
    }
  }
}

</script>

<style scoped>

</style>
<script setup lang="ts">
</script>