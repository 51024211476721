<template>
    <form id="form-checkout" method="post" @submit.prevent="onSubmit">
        <div class="mb-3">

            <div class="card rounded border-0 shadow">
                <div class="bg-light p-4 text-center rounded-top">
                    <h4 class="mb-0 card-title text-primary">Cartão de crédito</h4>
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="mb-3">
                                <label class="form-label" for="form-checkout__cardholderName">Nome: <span
                                    class="text-danger">*</span></label>
                                <input name="name" id="form-checkout__cardholderName" type="text"
                                       v-model="name"
                                       class="form-control fw-bold" required=""
                                       placeholder="Ex. João S Farias">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="mb-3">
                                <label class="form-label" for="credit_card_number">
                                    Número do cartão: <span class="text-danger">*</span>
                                </label>
                                <input type="text" autocomplete="off" id="form-checkout__cardNumber"
                                       v-model="cardNumber"
                                       class="form-control fw-bold" v-mask="'#### #### #### ####'"
                                       required="" placeholder="0000 0000 0000 0000">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label" for="credit_card_data_valid">Data de expiração: <span
                                    class="text-danger">*</span></label>
                                <input type="text" autocomplete="off" id="form-checkout__expirationDate"
                                       class="form-control fw-bold" v-model="expiration"
                                       required="" placeholder="MM/AAAA" v-mask="'##/####'">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label" for="credit_card_cvv">Código de segurança: <span
                                    class="text-danger">*</span></label>
                                <input type="text" autocomplete="off" id="form-checkout__securityCode"
                                       class="form-control fw-bold" v-model="securityCode"
                                       required="" placeholder="CVV" v-mask="'###'">
                            </div>
                        </div>
                        <div class="col-md-12" v-if="schedule.availabilityTime.avail_online">
                            <br>
                            <div class="mb-3">
                                <div class="form-check">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Ao agendar você concorda com
                                        <a target="_blank" href="https://policies.ubbimed.com.br/term-telemedicine.php"
                                           class="text-primary">
                                            Termos da teleconsulta.
                                        </a>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="d-grid">
                                <button class="btn btn-primary" type="submit" id="form-checkout__submit">
                                    Agendar consulta
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </form>
</template>

<script>
import {mask} from "vue-the-mask";

export default {
    name: "CreditCardGN",
    directives: {
        mask,
    },
    data() {
        return {
            ipAddress: null,
            securityCode: null,
            name: null,
            cardNumber: null,
            expiration: null,
            msgErrorHashCreditCard: '',
            ambiente: process.env.VUE_APP_REVERB_HOST === 'localhost' ? '1snn5n9w' : 'k8vif92e',
            sessionId: null,
        }
    },
    mounted() {
        fetch("https://checkip.amazonaws.com")
            .then(res => res.text())
            .then(ip => {
                this.ipAddress = ip;
            });

    },
    created() {
        this.sessionId = this.paymentMethod.id_paymeth + '' + this.userAuth.id_user + '' + this.schedule.availabilityTime.id_avail;
        let script = `https://h.online-metrix.net/fp/tags?org_id=${this.ambiente}&session_id=${this.sessionId}`;
        let tag = document.head.querySelector(`[src="${script}"`);
        tag = document.createElement("script");
        tag.setAttribute("src", script);
        tag.setAttribute("type", 'text/javascript');
        document.head.appendChild(tag);
    },
    computed: {
        paymentMethod() {
            return this.$store.state.toSchedule.schedule.paymentMethod;
        },
        schedule() {
            return this.$store.state.toSchedule.schedule;
        },
        userAuth() {
            return this.$store.state.auth.user;
        },
    },
    methods: {
        onSubmit() {
            let [month, year] = this.expiration.split('/');

            this.$emit('onSubmit', {
                security_code: this.securityCode,
                cardholder_name: this.name,
                expiration_month: month,
                expiration_year: year,
                ip_address: this.ipAddress,
                device_id: this.sessionId,
                credit_card_number: this.cardNumber,
            });
        }
    }
}
</script>

<style scoped>

</style>