<template>
    <section class="section-two bg-light mt-5 mb-2">
        <NoResult v-if="pixData == null"/>

        <div class="container-fluid" v-else>
            <div class="row m-3">
                <div class="col-lg-12 order-1 order-sm-2 order-md-2">
                    <div class="row">
                        <div class="col-md-6 mb-3 order-2 order-sm-1 order-md-1">
                            <div class="rounded shadow-lg p-4 sticky-bar">
                                <div class="d-flex mb-4">
                                    <h5>Escaneie este código QR para pagar</h5>
                                </div>
                                <div class="text-center border">
                                    <img :src="pixData?.pix_qrcode_img"
                                         id="image-qrcode-pix" style="width: 50%;"  alt="Pix" />
                                </div>
                                <div>
                                    <h4 class="title mt-4">Valor total: R$ {{pixData?.value?.toLocaleString('pt-br', {style: 'decimal', minimumFractionDigits: 2})}}</h4>
                                    <p>
                                        Nome: {{ pixData.receiver_name }} <br>
                                        CNPJ: {{ pixData.receiver_document }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 mb-3 order-1 order-sm-2 order-md-2">
                            <div class="rounded shadow-lg p-4 sticky-bar">
                                <div class="d-flex mb-4 justify-content-between">
                                    <h5>Faça o pagamento via Pix para confirmar o seu agendamento.</h5>
                                </div>
                                <p class="text-primary fw-bold">
                                    Para confirmar sua consulta, faça o pagamento via PIX em até 5 minutos, ou ela será cancelada.
                                </p>
                                <div>
                                    <p>1. Acesse seu Internet Banking ou app de pagamentos.</p>
                                    <p>2. Escolha pagar via Pix.</p>
                                    <p>3. Escaneie o qrcode</p>
                                </div>
                                <hr>
                                <div class="d-flex mb-4 justify-content-between">
                                    <h5>Ou copie este código para fazer o pagamento</h5>
                                </div>
                                <div>
                                    <p>
                                        Escolha pagar via Pix pelo seu Internet Banking ou app de pagamentos.
                                        <br>
                                        Depois, cole o seguinte código:
                                    </p>
                                </div>
                                <textarea class="form-control" id="code-pix"
                                          readonly rows="3">{{pixData?.pix_code}}</textarea>
                                <div class="d-grid gap-2">
                                    <button class="mt-3 rounded-4 btn btn-primary text-white btn-clipboard btn-lg px-4 gap-3"
                                            id="copyButton" @click="copyText">Copiar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
// @ is an alias to /src
import Alert from "../../helpers/alert";
import http from "../../helpers/http";
import NoResult from './NoResult.vue'

export default {
    name: 'Pix',
    components: {
        NoResult,
    },
    data() {
        return {
            intervalId: null,
        }
    },
    mounted() {
        history.pushState(null, null, window.location.href);
        this.intervalId = setInterval(this.checkPayment, 5000);
    },
    beforeUnmount() {
        clearInterval(this.intervalId);
    },
    computed: {
        pixData() {
            return this.$store.state.toSchedule.pixData;
        },
    },
    methods: {
        checkPayment() {
            if (!this.pixData.id_card_trans) {
                clearInterval(this.intervalId);
                return;
            }

            http.get('schedule/status-pix', {
                params: {
                    id: this.pixData.id_card_trans,
                }
            }).then(response => {
                const data = response.data;
                if (data.status === 'S') {
                    clearInterval(this.intervalId);

                    this.$router.push('/scheduled');
                    this.$store.commit('toSchedule/clearAll');
                } else if(['C', 'E'].includes(data.status)) {
                    clearInterval(this.intervalId);

                    this.$router.push('/pix-fail');
                    this.$store.commit('toSchedule/clearAll');
                }
            });
        },
        copyText() {
            const textarea = document.querySelector('#code-pix');
            textarea.select();
            document.execCommand('copy');
            textarea.selectionEnd = textarea.selectionStart;

            Alert.success('Código copiado com sucesso.')
        }
    }
}
</script>

<style scoped>

</style>
