<template>

  <div class="back-to-home rounded d-none d-sm-block">
    <router-link to="/" class="btn btn-icon btn-primary">
      <i data-feather="home" class="icons"></i>
    </router-link>
  </div>

  <section class="bg-home bg-circle-gradiant d-flex align-items-center">
    <div class="bg-overlay bg-overlay-white"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-8">
          <div class="card login-page bg-white shadow rounded border-0">
            <div class="card-body">
              <h4 class="card-title text-center">Alterar senha</h4>
              <form class="login-form mt-4" method="post" @submit.prevent="resetPassword">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label class="form-label">Senha <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i data-feather="user" class="fea icon-sm icons"></i>
                        <input type="password" class="form-control ps-5"
                               v-model="formPassword.user_password"
                               placeholder="Senha" name="password" required="">
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label class="form-label">Confirme a senha <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i data-feather="key" class="fea icon-sm icons"></i>
                        <input type="password" class="form-control ps-5"
                               v-model="formPassword.user_password_confirmation"
                               placeholder="Confirme a senha" required="">
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 mb-0">
                    <div class="d-grid">
                      <button type="submit" class="btn btn-primary">
                        Alterar senha
                        <span class="indicator-progress" v-show="submitted">Por favor aguarde...
										      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </button>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import http from "../helpers/http";
import Alert from "../helpers/alert";

export default {
  name: "ResetPassword",
  data() {
    return {
      errors: [],
      submitted: false,
      formPassword: {
        user_email: '',
        user_cpf: '',
        user_password: '',
        user_password_confirmation: '',
        token: '',
        origin: '',
      }
    }
  },
  mounted() {
    this.formPassword.token = this.$route.query.token;
    this.formPassword.user_email = this.$route.query.user_email;
    this.formPassword.user_cpf = this.$route.query.user_cpf;
    this.formPassword.origin = this.$route.query.origin;
  },
  methods: {
    resetPassword() {
      this.submitted = true;
      let origin = this.formPassword.origin;
      http.post(`/auth/password/reset`, this.formPassword).then((response) => {
        this.submitted = false;
        this.$store.commit('auth/setUser', response.data.user);
        this.$store.commit('auth/setToken', response.data.token);

        Alert.success("Senha alterada com sucesso.");

        this.formPassword = {
          user_email: '',
          user_password: '',
          user_password_confirmation: '',
          origin: '',
        }

        if (origin === 'schedule') {
          return this.$router.push('/schedule/patients');
        }

        this.$router.push('/schedules');
      }).catch((error) => {
        this.submitted = false;
        if (error.response.data?.errors) {
          this.errors = error.response.data.errors;
        } else {
          Alert.error(error.response.data)
        }
      });
    }
  }
}
</script>

<style scoped>

</style>