export default class Modal {
    static getInstance(querySelector) {
        const modalElement = document.querySelector(querySelector);
        return bootstrap.Modal.getOrCreateInstance(modalElement);
    }

    static toggle(querySelector) {
        const modalInstance = this.getInstance(querySelector);
        modalInstance.toggle();
    }

    static onShow(querySelector, callback) {
        const modalElement = document.querySelector(querySelector);
        modalElement.addEventListener('shown.bs.modal', callback);
    }

    static onClose(querySelector, callback) {
        const modalElement = document.querySelector(querySelector);
        modalElement.addEventListener('hide.bs.modal', callback);
    }

}