<template>
  <section class="bg-auth-home d-table w-100" style="padding-top: 60px; padding-bottom: 0">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-4">
          <div class="me-lg-5">
            <img src="../assets/images/signup.svg" class="img-fluid d-block mx-auto" alt="Foto">
          </div>
        </div>
        <div class="col-md-8">
          <div class="card shadow rounded border-0">
            <div class="card-body">
              <h4 class="card-title text-center">Cadastre-se</h4>
              <form class="login-form mt-4" method="post" @submit.prevent="onSubmit">
                <div class="row">
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">Nome <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                        <input type="text" class="form-control ps-5" placeholder="Seu nome"
                               :class="errors.user_name ? 'is-invalid' : ''"
                               v-model="register.user_name" required="">
                        <div class="invalid-feedback d-block" v-if="errors.user_name">
                          {{ errors.user_name.join('\n') }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">CPF <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check fea icon-sm icons"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                        <input type="tel" class="form-control ps-5" placeholder="Seu CPF"
                               :class="errors.user_cpf ? 'is-invalid' : ''"
                               v-mask="'###.###.###-##'"
                               v-model="register.user_cpf" required="" maxlength="20">
                        <div class="invalid-feedback d-block" v-if="errors.user_cpf">
                          {{ errors.user_cpf.join('\n') }}
                        </div>
                      </div>
                    </div>
                  </div>

                    <div class="col-md-6">
                        <div class="mb-3">
                            <label class="form-label">Data de Nascimento <span class="text-danger">*</span></label>
                            <div class="form-icon position-relative">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check fea icon-sm icons"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                                <input type="tel" class="form-control ps-5" placeholder="Data de nascimento" maxlength="14"
                                       :class="errors.user_birthdate ? 'is-invalid' : ''"
                                       v-mask="'##/##/####'" name="user_birthdate"
                                       v-model="register.user_birthdate" required="">
                                <div class="invalid-feedback d-block" v-if="errors.user_birthdate">
                                    {{ errors.user_birthdate.join('\n') }}
                                </div>
                            </div>
                        </div>
                    </div>

                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Whatsapp <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check fea icon-sm icons"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                        <input type="tel" class="form-control ps-5" placeholder="Seu Celular"
                               :class="errors.user_phone ? 'is-invalid' : ''"
                               v-mask="['(##) ####-####', '(##) #####-####']"
                               v-model="register.user_phone" required="" maxlength="15">
                        <div class="invalid-feedback d-block" v-if="errors.user_phone">
                          {{ errors.user_phone.join('\n') }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Seu e-mail <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail fea icon-sm icons"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                        <input type="email" class="form-control ps-5" placeholder="Seu e-mail"
                               :class="errors.user_email ? 'is-invalid' : ''" style="text-transform: lowercase;"
                               v-model="register.user_email" required="">
                        <div class="invalid-feedback d-block" v-if="errors.user_email">
                          {{ errors.user_email.join('\n') }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Escolha uma senha <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-key fea icon-sm icons"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg>
                        <input type="password" class="form-control ps-5" placeholder="Sua senha"
                               :class="errors.user_password ? 'is-invalid' : ''" autocomplete="new-password"
                               v-model="register.user_password" required="">
                        <div class="invalid-feedback d-block" v-if="errors.user_password">
                          {{ errors.user_password.join('\n') }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Repita a senha <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-key fea icon-sm icons"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg>
                        <input type="password" class="form-control ps-5" placeholder="Confirme a senha"
                               :class="errors.user_password ? 'is-invalid' : ''"
                               v-model="register.user_password_confirmation" required="">
                        <div class="invalid-feedback d-block" v-if="errors.user_password">
                          {{ errors.user_password.join('\n') }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="mb-3">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" required id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                          Concordo com
                          <a target="_blank" href="https://policies.ubbimed.com.br/terms.php" class="text-primary">
                            Termos e Condições
                          </a>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="d-grid">
                      <button type="submit" class="btn btn-primary">Cadastrar</button>
                    </div>
                  </div>

                  <div class="mx-auto">
                    <p class="mb-0 mt-3"><small class="text-dark me-2">Já tem uma conta ?</small>
                      <router-link to="/login" class="text-dark fw-bold">Entrar</router-link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import http from "../helpers/http";
import {mask} from "vue-the-mask";

export default {
  name: "Register",
    directives: {
        mask,
    },
  data() {
    return {
      showPassword: false,
      submitted: false,
      errors: [],
      register: {
        user_name: '',
        user_email: '',
        user_password: '',
        user_password_confirmation: '',
        user_cpf: '',
        user_birthdate: '',
        user_phone: '',
      }
    }
  },
  created() {
    if (this.$store.getters['auth/isAuth'])
      this.$router.push('/schedules');
  },
  methods: {
    onSubmit() {
      http.post(`/patients/register`, this.register).then((response) => {
        this.$store.commit('auth/setUser', response.data.user);
        this.$store.commit('auth/setToken', response.data.token);
        this.$router.push('/schedules');
      }).catch((error) => {
        let response = error.response;
        if (response?.status === 422) {
          this.errors = response.data.errors;
        } else if (response?.status === 401) {
          this.messageError = response.data;
        }
      }).finally(() => {
        this.submitted = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
