<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Meus Exames</h5>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
              <tr>
                <th scope="col">Profissional</th>
                <th scope="col">Data</th>
                <th scope="col">Paciente</th>
                <th scope="col">Ações</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="exam in exams">
                <td>
                  {{exam.professional?.prof_name}}
                </td>
                <td>
                  {{date(exam.created_at).format('DD/MM/YYYY')}}
                </td>
                <td>
                  {{exam.patient_name}}
                </td>
                <td>
                  <button class="btn btn-light btn-sm ms-2" data-bs-target="tooltip" title="Visualizar" @click="downloadPdf(exam,true)">
                    <i class="fa fa-eye"></i>
                  </button>
                  <button class="btn btn-light btn-sm ms-2" data-bs-target="tooltip" title="Fazer download" @click="downloadPdf(exam,false)">
                    <i class="fa fa-download"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="modal_download" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header" v-if="messagesGenerate.showBtnClose">
          <div class="btn btn-icon btn-sm btn-active-light-primary ms-2"
               data-bs-dismiss="modal" aria-label="Fechar">
            <i class="bi bi-x-lg"></i>
          </div>
        </div>
        <div class="modal-body">
          <div class="col-md-12 mb-6 mt-4">
            <div class="text-center px-5" :key="messagesGenerate.image">
              <video width="200" height="200" autoplay playsinline muted v-if="messagesGenerate.image.includes('.mp4')">
                <source :src="messagesGenerate.image" type="video/mp4" />
              </video>
              <img v-else-if="messagesGenerate.image" :src="messagesGenerate.image" alt="Imagem" class="mw-100 h-200px h-sm-325px">
              <p>{{ messagesGenerate.message }}</p>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="messagesGenerate.showBtnClose">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
        </div>
      </div>
    </div>
  </div>

  <PdfViewer id="modal_pdf_viewer_exam" :pdf-source="this.pdfSource" :file-name="this.fileName" key="exam"></PdfViewer>

</template>

<script>
import http from "../../helpers/http";
import date from "@/helpers/date";
import PdfViewer from "../components/PdfViewer";
import Download from "@/helpers/download";

export default {
  name: "Exam",
  components: {
    PdfViewer,
  },
  data() {
    return {
      exams: [],
      messagesGenerate: {
        message: '',
        image: '',
        showBtnClose: false,
      },
      pdfSource: '',
      fileName: '',
    }
  },
  computed: {
    userAuth() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    this.getExams();
  },
  methods: {
    date: date,
    getExams() {
      http.get(`/users/${this.userAuth.id_user}/exams`).then((response) => {
        this.exams = response.data;
      });
    },
    downloadPdf(exam, isView) {

      let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_download'));

      if (!isView) {
        modal.toggle();

        this.messagesGenerate = {
          message: 'Gerando, por favor, aguarde...',
          image: require('@/assets/mp4/8558-floating-document.mp4'),
          showBtnClose: false,
        }
      }

      http.get(`/users/${this.userAuth.id_user}/exams/${exam.id_exam_request}/pdf`).then(async (response) => {

        let data = response.data;

        if (isView) {

          this.pdfSource = `data:application/pdf;base64,${data.file_base64}`;
          this.fileName = data.authenticator.file.file_name;
          this.showPdfViewer();

        } else {
          await new Promise(r => setTimeout(r, 2000));

          this.messagesGenerate = {
            message: 'Baixando ...',
            image: require('@/assets/mp4/97795-download-green.mp4'),
            showBtnClose: false,
          }

          Download.base64File('application/pdf', data.file_base64, data.authenticator.file.file_name);

          setTimeout( async () => {
            this.messagesGenerate = {
              message: 'Download realizado com sucesso.',
              image: require('@/assets/mp4/96673-success.mp4'),
              showBtnClose: true,
            }

            await new Promise(r => setTimeout(r, 2000));
            modal.toggle();

          }, 2500);
        }


      }).catch((e) => {
        this.messagesGenerate = {
          message: e.response.data,
          image: require('@/assets/mp4/4386-connection-error.mp4'),
          showBtnClose: true,
        }
      });
    },
    showPdfViewer() {
      let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_pdf_viewer_exam'));
      modal.toggle();
    },
  }
}
</script>

<style scoped>

</style>