<template>
    <select v-model="selectedValue" class="form-control" :id="id" :required="required"
            @change="$emit('update:modelValue', $event.target.value);
                     $emit('change', $event.target.value);">
        <option v-for="(option, key) in filteredOptions"
                :key="key" :value="option[this.value]">
            {{ option[this.label] }}
        </option>
    </select>
</template>

<script>
export default {
    name: "InputSelect",
    props: ['options', 'label', 'value', 'modelValue', 'id', 'required'],
    emits: ['update:modelValue', 'change'],
    data: () => ({
        searchQuery: '',
        selectedValue: null,
    }),
    watch: {
        modelValue(newValue, _) {
            this.selectedValue = newValue;
        }
    },
    computed: {
        filteredOptions() {
            return this.options?.filter(option =>
                option[this.label].toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        },
    },
}
</script>

<style scoped>

</style>