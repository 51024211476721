<template>
  <div>
    <div id="preloader" v-show="loading">
      <div id="status">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
      </div>
    </div>

    <!-- Navbar STart -->
    <header id="topnav" class="defaultscroll sticky nav-sticky">
      <div class="container" :class="place?.place_logo ? 'container-place' : ''">
        <a class="logo" href="https://ubbimed.com.br">
            <img :src="require('@/assets/images/logo_ubbi.png')" height="50" class="logo-light-mode me-3" alt="">

            <img :src="place?.place_logo" v-if="place?.place_logo && $route.meta.scheduling"
                 height="26" class="logo-light-mode mt-3" alt="">
        </a>
        <div class="buy-button">
          <div v-if="isAuthenticated">
            <router-link class="btn btn-link" to="/schedules">
              <div v-if="userAuth?.user_photo">
                <img :src="url(userAuth.user_photo)"
                     class="avatar avatar-small rounded-circle shadow" alt="Minha Foto" style="width: 40px; height: 40px">
                {{userAuth?.first_name}}
              </div>
              <div v-else>
                <span style="font-size: 20px" class="avatar avatar-small rounded-circle shadow pe-2 ps-2 pt-1 pb-1">
                  {{initialsName()}}
                </span>
                {{userAuth?.first_name}}
              </div>
            </router-link>
          </div>
          <div v-else>

              <div class="btn-group dropdown-primary">
                  <button type="button" :class="isMobile ? 'btn-sm h6 mt-1' : ''"
                          class="btn btn-link" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Entrar
                  </button>
                  <div class="dropdown-menu">
                      <router-link to="/login" class="dropdown-item">
                          Sou paciente
                      </router-link>
                      <div class="dropdown-divider"></div>
                      <a href="https://professional.ubbimed.com.br/login" class="dropdown-item">Sou profissional</a>
                  </div>
              </div>

              <div class="btn-group dropdown-primary">
                  <button type="button" :class="isMobile ? 'btn-sm' : ''" class="btn btn-primary dropdown-toggle"
                          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Cadastre-se
                  </button>
                  <div class="dropdown-menu">
                      <router-link to="/register" class="dropdown-item">
                          Sou paciente
                      </router-link>
                      <div class="dropdown-divider"></div>
                      <a href="https://professional.ubbimed.com.br/register" class="dropdown-item">Sou profissional</a>
                  </div>
              </div>
          </div>
        </div>

        <div class="menu-extras">
          <div class="menu-item">
            <a class="navbar-toggle" id="isToggle" @click="toggleMenu">
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
          </div>
        </div>

        <div id="navigation">
          <ul class="navigation-menu">
            <li>
              <router-link to="/" class="sub-menu-item">Home</router-link>
            </li>
            <li>
              <a href="https://ubbimed.com.br/index.html#us" class="sub-menu-item">Quem somos</a>
            </li>
            <li>
              <a href="https://ubbimed.com.br/index.html#how_works" class="sub-menu-item">Como funciona</a>
            </li>
            <li>
              <a :href="urlProfessional" class="sub-menu-item">Para Profissionais</a>
            </li>
          </ul>

          <div class="buy-menu-btn d-none">
            <div v-if="isAuthenticated">
              <router-link to="/schedules" class="btn btn-link">
                {{userAuth.first_name}}
              </router-link>
              <button class="btn btn-link" type="button" title="Sair" @click="logout">
                <i class="fas fa-sign-out-alt"></i>
              </button>
            </div>
            <div v-else>
              <router-link to="/login" class="btn btn-link">
                Entrar
              </router-link>
              <router-link to="/register" class="btn btn-primary">
                Cadastre-se
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </header>

    <router-view/>

    <footer class="footer footer-border">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
            <a href="#" class="logo-footer">
              <img :src="require('@/assets/images/logo_ubbi.png')" alt="">
            </a>
            <p class="mt-4">Consultas e exames a preços acessíveis, na palma da sua mão.</p>
            <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
              <li class="list-inline-item">
                <a href="https://www.facebook.com/ubbimed" target="_blank" class="rounded">
                  <i class="fab mt-2 fa-facebook"></i>
                </a>
              </li>
              <li class="list-inline-item ms-1">
                <a href="https://www.instagram.com/ubbimed" target="_blank" class="rounded">
                  <i class="fab mt-2 fa-instagram"></i>
                </a>
              </li>
              <li class="list-inline-item ms-1">
                <a href="https://twitter.com/MedUbbi" target="_blank" class="rounded">
                  <i class="fab mt-2 fa-twitter"></i>
                </a>
              </li>
              <li class="list-inline-item ms-1">
                <a href="https://www.linkedin.com/company/ubbimed" target="_blank" class="rounded">
                  <i class="fab mt-2 fa-linkedin-in"></i>
                </a>
              </li>
              <li class="list-inline-item ms-1">
                <a href="https://www.youtube.com/channel/UCmOKmvrCvOZs0x30Q_6rB0Q" target="_blank" class="rounded">
                  <i class="fab mt-2 fa-youtube"></i>
                </a>
              </li>
            </ul>
          </div>

          <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">Empresa</h5>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <a href="https://ubbimed.com.br/index.html#us" class="text-foot">
                  <i class="uil uil-angle-right-b me-1"></i>
                  Sobre
                </a>
              </li>
              <li>
                <a target="_blank" href="https://blog.ubbimed.com.br" class="text-foot">
                  <i class="uil uil-angle-right-b me-1"></i>
                  Blog
                </a>
              </li>
              <li>
                <router-link to="/login" class="text-foot">
                  <i class="uil uil-angle-right-b me-1"></i>
                  Login
                </router-link>
              </li>
              <li>
                <router-link to="/register" class="text-foot">
                  <i class="uil uil-angle-right-b me-1"></i>
                  Cadastre-se
                </router-link>
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">Links</h5>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <a target="_blank" href="https://policies.ubbimed.com.br/terms.php" class="text-foot">
                  <i class="uil uil-angle-right-b me-1"></i>
                  Termos de uso
                </a>
              </li>
              <li>
                <a target="_blank" href="https://policies.ubbimed.com.br/privacy.php" class="text-foot">
                  <i class="uil uil-angle-right-b me-1"></i>
                  Política de privacidade
                </a>
              </li>
              <li>
                <a :href="urlProfessional" class="text-foot">
                  <i class="uil uil-angle-right-b me-1"></i>
                  Para profissionais
                </a>
              </li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">Download App</h5>
            <p class="mt-4">Faça como milhares de usuários, marque suas consultas a qualquer momento, de qualquer lugar.</p>
            <div class="row">
              <div class="col-md-12">
                <div class="d-grid">
                  <a href="https://ubbimed.com.br/" target="_blank" class="btn btn-primary mt-2 me-2">
                    <i class="uil uil-apple"></i> App Store
                  </a>
                </div>
              </div>
              <div class="col-md-12">
                <div class="d-grid">
                  <a href="https://ubbimed.com.br/" target="_blank" class="btn btn-outline-primary mt-2 me-2">
                    <i class="uil uil-google-play"></i> Play Store
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <footer class="footer footer-bar">
      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="text-sm-start">
              <p class="mb-0">© {{new Date().getFullYear()}} Ubbimed. Feito com <i class="mdi mdi-heart text-danger"></i> em Araras.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import {initTemplate, toggleMenu} from "@/assets/js/app.js";
import str from "@/helpers/str";
import Swal from "sweetalert2";

export default {
  name: "Guest",
  data() {
    return {
      loading: true,
        isMobile: window.matchMedia('(max-width: 700px)').matches,
    }
  },
  mounted() {
    this.loading = false;
    initTemplate();
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/isAuth'];
    },
    userAuth() {
      return this.$store.state.auth.user;
    },
    urlProfessional() {
      return this.$store.state.urlProfessional;
    },
      place() {
          return this.$store.state.toSchedule.place;
      },
  },
  methods: {
    toggleMenu: toggleMenu,
      url(path) {
          return str.url(path)
      },
    initialsName() {
      return str.initialsName(this.userAuth?.user_name);
    },
    logout() {
      Swal.fire({
        title: 'Sair',
        text: "Tem certeza que deseja sair?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#780f5a',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, Sair!',
        cancelButtonText: "Não"
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('auth/logout').then(() => {
            this.$router.push('/');
          });
        }
      })
    },
  }
}
</script>

<style scoped>
@media (min-width: 1400px) {
    .container-place {
        max-width: 1240px !important;
    }
}
</style>