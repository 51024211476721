<template>
  <div class="d-flex flex-column min-vh-100">
    <div id="preloader" v-show="loading">
      <div id="status">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
      </div>
    </div>

    <header id="topnav" class="defaultscroll sticky nav-sticky">
      <div class="container">
        <a class="logo" href="https://ubbimed.com.br">
          <img :src="require('@/assets/images/logo_ubbi.png')" height="50" class="logo-light-mode" alt="">
        </a>

        <div class="buy-button">
          <router-link class="btn btn-link" to="/schedules">
            <div v-if="userAuth?.user_photo">
              <img :src="url(userAuth?.user_photo)"
                   class="avatar avatar-small rounded-circle shadow" alt="Minha Foto" style="width: 40px; height: 40px">
              {{userAuth?.first_name}}
            </div>
            <div v-else>
              <span style="font-size: 20px" class="avatar avatar-small rounded-circle shadow pe-2 ps-2 pt-1 pb-1">
                {{initialsName()}}
              </span>
              {{userAuth?.first_name}}
            </div>
          </router-link>
        </div>

        <div class="menu-extras">
          <div class="menu-item">
            <a class="navbar-toggle" id="isToggle" @click="toggleMenu">
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
          </div>
        </div>

        <div id="navigation">
          <ul class="navigation-menu d-none d-md-inline">
            <li>
              <router-link to="/" class="sub-menu-item">Home</router-link>
            </li>
            <li>
              <a href="https://ubbimed.com.br/index.html#us" class="sub-menu-item">Quem somos</a>
            </li>
            <li>
              <a href="https://ubbimed.com.br/index.html#how_works" class="sub-menu-item">Como funciona</a>
            </li>
            <li>
              <a :href="urlProfessional" class="sub-menu-item">Para Profissionais</a>
            </li>
          </ul>

          <ul class="navigation-menu d-block d-sm-none">
            <li>
              <router-link to="/schedules" class="sub-menu-item" @click="toggleMenu">Minhas Consultas</router-link>
            </li>
            <li>
              <router-link to="/exams" class="sub-menu-item" @click="toggleMenu">Exames</router-link>
            </li>
            <li>
              <router-link to="/prescriptions" class="sub-menu-item" @click="toggleMenu">Receitas</router-link>
            </li>
            <li>
              <router-link to="/documents" class="sub-menu-item" @click="toggleMenu">Documentos</router-link>
            </li>
            <li>
              <router-link to="/patients" class="sub-menu-item" @click="toggleMenu">Dependentes</router-link>
            </li>
            <li>
              <router-link to="/profile" class="sub-menu-item" @click="toggleMenu">Perfil</router-link>
            </li>
          </ul>

          <div class="d-block d-sm-none">
            <router-link class="btn btn-link" to="/schedules">
              {{userAuth?.first_name}}
            </router-link>
            <br>
            <button class="btn btn-link" type="button" title="Sair" @click.prevent="logout">
              Sair <i class="fas fa-sign-out-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </header>

    <section class="bg-profile d-table w-100" id="profile-section">
      <div class="container">
        <div class="row justify-content-md-between">

            <div class="public-profile">

              <div class="row">
                <div class="col-md-8 text-center">

                  <div class="text-light">
                    Olá, {{userAuth?.first_name}} <br>
                    É bom te ver de volta 😉
                  </div>
                </div>

                <div class="col-md-4 text-md-end text-center d-none d-md-inline">
                  <router-link to="/" class="btn btn-light mt-1">
                    Nova Consulta
                  </router-link>
                </div>
              </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section-two bg-light" style="padding-top: 25px;">
      <div class="container">
        <div class="row">

          <div class="col-lg-4 col-md-6 col-12 mb-2 d-none d-md-inline mb-5">
            <div class="card sidebar sticky-bar p-4 rounded shadow mb-5">

              <div class="widget">
                <h5 class="widget-title">Menu:</h5>
                <ul class="list-unstyled sidebar-nav mb-0" id="navmenu-nav">

                  <li class="navbar-item account-menu px-0 mt-2" :class="isActiveClass('/schedules')">
                    <router-link to="/schedules" class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                      <span class="h4 mb-0">
                        <i class="uil uil-monitor-heart-rate"></i>
                      </span>
                      <h6 class="mb-0 ms-2">Minhas Consultas</h6>
                    </router-link>
                  </li>

                  <li class="navbar-item account-menu px-0 mt-2" :class="isActiveClass('/exams')">
                    <router-link to="/exams" class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                      <span class="h4 mb-0">
                        <i class="fas fa-notes-medical"></i>
                      </span>
                      <h6 class="mb-0 ms-2">Exames</h6>
                    </router-link>
                  </li>

                  <li class="navbar-item account-menu px-0 mt-2" :class="isActiveClass('/prescriptions')">
                    <router-link to="/prescriptions" class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                      <span class="h4 mb-0">
                        <i class="fas fa-file-medical-alt"></i>
                      </span>
                      <h6 class="mb-0 ms-2">Receitas</h6>
                    </router-link>
                  </li>

                  <li class="navbar-item account-menu px-0 mt-2" :class="isActiveClass('/documents')">
                    <router-link to="/documents" class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                      <span class="h4 mb-0">
                        <i class="fas fa-file-medical"></i>
                      </span>
                      <h6 class="mb-0 ms-2">Documentos</h6>
                    </router-link>
                  </li>

                  <li class="navbar-item account-menu px-0 mt-2" :class="isActiveClass('/patients')">
                    <router-link to="/patients" class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                      <span class="h4 mb-0">
                        <i class="uil uil-users-alt"></i>
                      </span>
                      <h6 class="mb-0 ms-2">Dependentes</h6>
                    </router-link>
                  </li>

                  <li class="navbar-item account-menu px-0 mt-2" :class="isActiveClass('/profile')">
                    <router-link to="/profile" class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                      <span class="h4 mb-0">
                        <i class="uil uil-setting"></i>
                      </span>
                      <h6 class="mb-0 ms-2">Perfil</h6>
                    </router-link>
                  </li>

                  <li class="navbar-item account-menu px-0 mt-2">
                    <a href="#" @click.prevent="logout" class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                      <span class="h4 mb-0">
                        <i class="uil uil-sign-in-alt"></i>
                      </span>
                      <h6 class="mb-0 ms-2">Sair</h6>
                    </a>
                  </li>

                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-8">
              <router-view :key="$route.fullPath" />
          </div>
        </div>
      </div>
    </section>

    <footer class="footer footer-bar" style="margin-top: auto;">
      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="text-sm-start">
              <p class="mb-0">
                © {{new Date().getFullYear()}} Ubbimed. Feito com <i class="mdi mdi-heart text-danger"></i> em Araras.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>

  </div>
</template>

<script>
import {initTemplate, toggleMenu} from "@/assets/js/app";
import str from "@/helpers/str";
import Swal from "sweetalert2";

export default {
  name: "Patient",
  data() {
    return {
      loading: true,
    }
  },
  mounted() {
    this.loading = false;
    initTemplate();
  },
  computed: {
    userAuth() {
      return this.$store.state.auth.user;
    },
    urlProfessional() {
      return this.$store.state.urlProfessional;
    }
  },
  methods: {
    toggleMenu: toggleMenu,
      url(path) {
          return str.url(path)
      },
    logout() {
      Swal.fire({
        title: 'Sair',
        text: "Tem certeza que deseja sair?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#780f5a',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, Sair!',
        cancelButtonText: "Não"
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('auth/logout').then(() => {
            this.$router.push('/');
          });
        }
      })
    },
    initialsName() {
      return str.initialsName(this.userAuth?.user_name);
    },
    isActiveClass(path) {
      return this.$router.currentRoute.value.fullPath === path ? 'active' : '';
    }
  },
}
</script>

<style scoped>
#profile-section {
  background-color: rgba(120, 15, 90, 0.80) !important;
}
</style>