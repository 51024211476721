import http from "../helpers/http";
import db from "../helpers/db";
import date from "../helpers/date";

export default {
    namespaced: true,
    state: {
        schedule: {
            patient: db.toSchedule.patient,
            availabilityTime: db.toSchedule.availabilityTime,
            paymentMethod: db.toSchedule.paymentMethod,
            insurance: db.toSchedule.insurance,
            cancelingPolicy: db.toSchedule.cancelingPolicy,
            professional: db.toSchedule.professional,
            specialty: db.toSchedule.specialty,
            city: db.toSchedule.city,
        },
        cities: [],
        specialties: [],
        paymentMethods: [],
        insurances: [],
        professionals: [],
        patients: [],
        cancelingPolicies: [],
        pixData: db.toSchedule.pixData,
        scheduleType: db.toSchedule.scheduleType, // DEFAULT, PROFESSIONAL, PLACE
        attendanceType: db.toSchedule.attendanceType, // ONLINE, ONSITE, EXAM
        place: db.toSchedule.place,
    },
    mutations: {
        setPlace(state, place) {
            state.place = db.toSchedule.storePlace(place);
        },
        setScheduleType(state, type) {
            state.scheduleType = db.toSchedule.storeScheduleType(type);
        },
        setAttendanceType(state, type) {
            state.attendanceType = db.toSchedule.storeAttendanceType(type);
        },
        setCities(state, cities) {
            state.cities = cities;
        },
        selectCity(state, city) {
            state.schedule.city = db.toSchedule.storeCity(city);
        },
        setSpecialties(state, specialties) {
            state.specialties = specialties;
        },
        selectSpecialty(state, specialty) {
            state.schedule.specialty = db.toSchedule.storeSpecialty(specialty);
        },
        setPaymentMethods(state, paymentMethods) {
            state.paymentMethods = paymentMethods;
        },
        selectPaymentMethod(state, paymentMethod) {
            state.schedule.paymentMethod = db.toSchedule.storePaymentMethod(paymentMethod);
            state.schedule.insurance = db.toSchedule.storeInsurance(null);
            state.schedule.cancelingPolicy = db.toSchedule.storeCancelingPolicy(null);
        },
        setInsurances(state, insurances) {
            state.insurances = insurances;
        },
        selectInsurance(state, insurance) {
            state.schedule.insurance = db.toSchedule.storeInsurance(insurance);
        },
        setProfessionals(state, professionals) {
            state.professionals = professionals;
        },
        updateNextAvailabilityFromProfessional(state, param) {
            let availability = param.availability;
            let indexProf = state.professionals.findIndex((prof) => prof.id_prof === param.professional.id_prof);

            let currentAvailabilityFromProf = state.professionals[indexProf].availability_dates;
            let currentLastDateFromProf     = currentAvailabilityFromProf[currentAvailabilityFromProf.length -1].available_date_patient;
            let newLastDateFromProf         = availability[availability.length -1].available_date_patient;

            if (date(newLastDateFromProf) > date(currentLastDateFromProf))
                state.professionals[indexProf].availability_dates = availability;
        },
        updatePreviousAvailabilityFromProfessional(state, param) {
            let availability = param.availability;
            let indexProf = state.professionals.findIndex((prof) => prof.id_prof === param.professional.id_prof);

            let currentAvailabilityFromProf = state.professionals[indexProf].availability_dates;
            let currentFirstDateFromProf    = currentAvailabilityFromProf[0].available_date_patient;
            let newFirstDateFromProf        = availability[0].available_date_patient;

            if (date(newFirstDateFromProf) < date(currentFirstDateFromProf))
                state.professionals[indexProf].availability_dates = availability;
        },
        selectAvailability(state, availability) {
            state.schedule.professional = db.toSchedule.storeProfessional(availability.professional);
            state.schedule.availabilityTime = db.toSchedule.storeAvailabilityTime(availability.availabilityTime);
        },
        setPatients(state, patients) {
            state.patients = patients;
        },
        selectPatient(state, patient) {
            state.schedule.patient = db.toSchedule.storePatient(patient);
        },
        setCancelingPolicies(state, cancelingPolicies) {
            state.cancelingPolicies = cancelingPolicies;
        },
        setPixData(state, pixData) {
            state.pixData = db.toSchedule.storePixData(pixData);
        },
        selectCancelingPolicy(state, cancelingPolicy) {
            state.schedule.cancelingPolicy = db.toSchedule.storeCancelingPolicy(cancelingPolicy);
        },
        clearAll(state) {
            state.schedule.city = db.toSchedule.storeCity(null);
            state.schedule.specialty = db.toSchedule.storeSpecialty(null);
            state.schedule.insurance = db.toSchedule.storeInsurance(null);
            state.schedule.paymentMethod = db.toSchedule.storePaymentMethod(null);
            state.schedule.professional = db.toSchedule.storeProfessional(null);
            state.schedule.availabilityTime = db.toSchedule.storeAvailabilityTime(null);
            state.schedule.patient = db.toSchedule.storePatient(null);
            state.schedule.cancelingPolicy = db.toSchedule.storeCancelingPolicy(null);
            state.pixData = db.toSchedule.storePixData(null);
            state.place = db.toSchedule.storePlace(null);
            state.scheduleType = db.toSchedule.storeScheduleType(null);
            state.attendanceType = db.toSchedule.storeAttendanceType(null);
        }
    },
    actions: {
        getCitiesSupported(context) {
            http.get('/schedule/cities/supported', {
                params: {
                    kind_exam: context.state.attendanceType === 'EXAM',
                }
            }).then((cities) => {
                context.commit('setCities', cities.data)
            });
        },
        getSpecialties(context) {
            http.get('/schedule/specialties', {
                params: {
                    id_city: context.state.schedule.city?.id_city,
                    id_place: context.state.place?.id_place,
                    kind_exam: context.state.attendanceType === 'EXAM',
                }
            }).then((specialties) => {
                context.commit('setSpecialties', specialties.data)
            });
        },
        getPaymentMethods(context) {
            http.get('/schedule/payment-methods', {
                params: {
                    id_city: context.state.schedule.city?.id_city,
                    id_spec: context.state.schedule.specialty.id_spec,
                    id_place: context.state.place?.id_place,
                }
            }).then((paymentMethods) => {
                context.commit('setPaymentMethods', paymentMethods.data)
            });
        },
        getInsurances(context) {
            http.get('/schedule/insurances', {
                params: {
                    id_city: context.state.schedule.city?.id_city,
                    id_spec: context.state.schedule.specialty.id_spec,
                    id_paymeth: context.state.schedule.paymentMethod.id_paymeth,
                    id_prof: context.state.schedule.professional?.id_prof,
                    id_place: context.state.place?.id_place,
                }
            }).then((insurances) => {
                context.commit('setInsurances', insurances.data)
            });
        },
        getProfessionals(context, param) {
            return http.get('/schedule/professionals', {
                params: {
                    id_city: context.state.schedule.city?.id_city,
                    id_spec: context.state.schedule.specialty.id_spec,
                    id_paymeth: context.state.schedule.paymentMethod.id_paymeth,
                    id_insurance: context.state.schedule.insurance?.id_insurance,
                    id_place: context.state.place?.id_place,
                    with_availability: true,
                    start_end_days: param.start_end_days,
                }
            }).then((professionals) => {
                context.commit('setProfessionals', professionals.data);
                return professionals;
            });
        },
        paginateAvailabilityProfessional(context, param) {
            let professional = param.professional;
            let startDate    = param.start_date;
            let startEndDays = param.start_end_days;
            return http.get('/schedule/availabilities/dates-times', {
                params: {
                    id_place: professional.id_place,
                    id_prof: professional.id_prof,
                    id_spec: context.state.schedule.specialty.id_spec,
                    id_city: context.state.schedule.city?.id_city,
                    id_paymeth: context.state.schedule.paymentMethod.id_paymeth,
                    id_insurance: context.state.schedule.insurance?.id_insurance,
                    start_date: startDate,
                    start_end_days: startEndDays,
                }
            });
        },
        paginatePreviousAvailabilityProfessional(context, param) {
            return context.dispatch('paginateAvailabilityProfessional', param).then((response) => {
                context.commit('updatePreviousAvailabilityFromProfessional', {
                    professional: param.professional,
                    availability: response.data,
                });

                return response;
            });
        },
        paginateNextAvailabilityProfessional(context, param) {
            return context.dispatch('paginateAvailabilityProfessional', param).then((response) => {
                context.commit('updateNextAvailabilityFromProfessional', {
                    professional: param.professional,
                    availability: response.data,
                });

                return response;
            });
        },
        getPatients(context) {
            let userAuth = context.getters.userAuth;
            http.get(`/users/${userAuth.id_user}/patients`).then((patients) => {
                context.commit('setPatients', patients.data)
            });
        },
        getCancelingPolicies(context) {
            http.get('/schedule/canceling-policies', {
                params: {
                    id_place: context.state.schedule.professional.id_place,
                    id_spec: context.state.schedule.specialty.id_spec,
                    id_prof: context.state.schedule.professional.id_prof,
                    sched_date: context.state.schedule.availabilityTime.available_date,
                    sched_time: context.state.schedule.availabilityTime.available_time,
                }
            }).then((cancelingPolicies) => {
                context.commit('setCancelingPolicies', cancelingPolicies.data)
            });
        },
        scheduleAppointment(context, schedParams) {
            let parameterCreditCard = schedParams.creditCard;
            let userAddress = schedParams.userAddress;
            let schedule = context.state.schedule;
            let userAuth = context.getters.userAuth;
            let param = {
                sched_date: schedule.availabilityTime.available_date,
                sched_time: schedule.availabilityTime.available_time,
                id_patient: schedule.patient.id_patient,
                id_user: userAuth.id_user,
                id_avail: schedule.availabilityTime.id_avail,
                id_place: schedule.professional.id_place,
                id_prof: schedule.professional.id_prof,
                id_spec: schedule.specialty.id_spec,
                id_city: schedule.city?.id_city,
                id_visit_value: schedule.cancelingPolicy?.id_visit_value,
                id_paymeth: schedule.paymentMethod.id_paymeth,
                id_insurance: schedule.insurance?.id_insurance,
                sched_by: 'US',
                sched_online: schedule.availabilityTime.avail_online,
                user_address: userAddress,
                sched_recommended: schedule.professional.sched_recommended,

                gateway_payment_credit_card_data: parameterCreditCard,
            };

            return http.post('/schedule/appointment', param).then((response) => {
                if (schedule.paymentMethod.is_pix) {
                    context.commit('setPixData', response.data);

                    return response;
                } else {
                    context.commit('clearAll');
                    return response;
                }
            });
        }
    },
    getters: {
        userAuth(state, _, rootState) {
            return rootState.auth.user;
        },
        tokenAuth(state, _, rootState) {
            return rootState.auth.user;
        },
    }
};