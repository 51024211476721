<template>
  <div class="row">
    <div id="google-signin-button" @click="doLogin" class="btn btn-light ms-1">
      <i class="mdi mdi-google text-danger"></i> Google
    </div>
  </div>
</template>

<script>
export default {
  name: "BtnLoginGoogle",
  mounted() {
      let script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.type = 'text/javascript';

      document.head.appendChild(script);
  },
  methods: {
    doLogin() {
        google.accounts.id.initialize({
            client_id: '1005397862007-q171itm6sjnhibgvqb87lmeo9fmf4024.apps.googleusercontent.com',
            scope: 'openid email profile',
            callback: (googleUser) => {
                this.$emit('onSuccess', googleUser);
            }
        });

        google.accounts.id.prompt();
    },
  }
}
</script>

<style scoped>

</style>