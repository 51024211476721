<template>
  <section class="section bg-light mt-5 mb-2">
    <div class="container-fluid">
      <div class="row m-3">
        <div class="col-lg-6 offset-lg-3 order-1 order-sm-2 order-md-2">
          <h4>Forma de pagamento</h4>
          <br>
          <div class="card shadow rounded border-0 mb-3 pricing-rates business-rate"
               @click="selectPaymentMethod(paymentMethod)"
               style="cursor: pointer;" v-for="paymentMethod in paymentMethods">
            <div class="m-3">
              <b class="h6 text-primary">{{paymentMethod.paymeth_name}}</b>
              <i class="ms-1 fas fa-1x fa-arrow-circle-right text-primary"></i>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>

import http from "../../../helpers/http";

export default {
  name: 'ProfessionalPaymentMethod',
  data() {
    return {
      search: '',
    }
  },
  computed: {
    paymentMethods() {
      let paymentMethods = this.$store.state.toSchedule.paymentMethods;
      if (this.search === '')
        return paymentMethods;

      return paymentMethods.filter(paymentMethod => {
        let strFilter = JSON.stringify(paymentMethod);
        return strFilter.toLowerCase().includes(this.search.toLowerCase())
      });
    },
    professional() {
      return this.$store.state.toSchedule.schedule.professional;
    },
  },
  mounted() {
    http.get('/schedule/payment-methods', {
      params: {
        id_city: this.$store.state.toSchedule.schedule.city?.id_city,
        id_spec: this.$store.state.toSchedule.schedule.specialty?.id_spec,
        id_prof: this.professional.id_prof,
      }
    }).then((response) => {
      this.$store.commit('toSchedule/setPaymentMethods', response.data)
    });
  },
  methods: {
    selectPaymentMethod(paymentMethod) {
      this.$store.commit('toSchedule/selectPaymentMethod', paymentMethod);
      if (paymentMethod.is_insurance) {
        this.$router.push(`/professional/${this.professional.id_prof}/insurances`);
      } else {
        if (this.$store.getters['auth/isAuth']) {
          this.$router.push('/schedule/patients');
        } else {
          this.$router.push('/schedule/login');
        }
      }
    },

  }
}
</script>

<style scoped>

</style>
