<template>
  <section class="section-two bg-light mt-5 mb-2">
    <div class="container-fluid">
      <div class="row m-3">

        <div class="col-lg-3 mb-4 order-2 order-sm-1 order-md-1">
          <MenuAction/>
        </div>

        <div class="col-lg-9 order-1 order-sm-2 order-md-2">
            <p class="col-md-6 h4" style="display: none;">
                <b>Escolha a política de agendamento</b>
            </p>
          <div class="row">
            <div class="col-md-4" v-for="cancelingPolicy in cancelingPolicies">
              <div class="card shadow rounded border-0 mb-3 pricing-rates business-rate" style="cursor: pointer; min-height: 360px"
                   @click="selectCancelingPolicy(cancelingPolicy)">
                <div class="ribbon ribbon-right ribbon-warning overflow-hidden" v-if="cancelingPolicy.is_common">
                  <span class="text-center d-block shadow small h6">Popular</span>
                </div>
                <div class="card-body">
                    <h5 class="card-title text-center">{{ cancelingPolicy.policy_cancel_name }}</h5>
                    <span class="text-center" v-html="cancelingPolicy.policy_cancel_desc"></span>
                    <hr>

                  <div class="col-md-12">
                    <ul class="list-unstyled text-muted">
                      <li class="mb-0">
                        <span class="text-primary h5 me-2">
                          <i class="text-success uil uil-check-circle align-middle" v-if="cancelingPolicy.perm_return && cancelingPolicy.returning_deadline > 0"></i>
                          <i class="text-danger uil uil-minus-circle align-middle" v-else></i>
                        </span>
                        <span v-if="cancelingPolicy.perm_return && cancelingPolicy.returning_deadline > 0">
                          Você tem até <b>{{ cancelingPolicy.returning_deadline }}</b> dias para agendar o retorno.
                        </span>
                        <span v-else>
                          Não é permitido agendar retorno
                        </span>
                      </li>
                      <li class="mb-0">
                        <span class="h5 me-2">
                          <i class="text-success uil uil-check-circle align-middle" v-if="cancelingPolicy.reschedule_deadline > 0"></i>
                          <i class="text-danger uil uil-minus-circle align-middle" v-if="cancelingPolicy.reschedule_deadline <= 0"></i>
                        </span>
                        <span v-if="cancelingPolicy.reschedule_deadline > 0">
                        Você tem até <b>{{ cancelingPolicy.reschedule_deadline }}</b> dias para reagendar.
                        </span>
                        <span v-else>
                        Não é permitido reagendamento
                      </span>
                      </li>
                      <li class="mb-0">
                        <span class="h5 me-2">
                          <i class="text-success uil uil-check-circle align-middle" v-if="cancelingPolicy.policy_cancel_deadline > 0"></i>
                          <i class="text-danger uil uil-minus-circle align-middle" v-if="cancelingPolicy.policy_cancel_deadline <= 0"></i>
                        </span>
                        <span v-if="cancelingPolicy.policy_cancel_deadline > 0">
                           O cancelamento pode ser feito em até <b>{{cancelingPolicy.policy_cancel_deadline}}</b> dias.
                        </span>
                        <span v-else>
                          Não é permitido cancelamento
                        </span>
                      </li>
                    </ul>

                    <div class="d-flex justify-content-center">
                      <span class="h4 mb-0 mt-2">R$</span>
                      <span class="price h1 mb-0">{{ cancelingPolicy.value.split('.')[0] }}</span>
                      <span class="h4 align-self-end mb-1">,{{ cancelingPolicy.value?.split('.')[1] ?? '00' }}</span>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import MenuAction from './MenuAction.vue'

export default {
  name: 'CancelingPolicy',
  components: {
    MenuAction
  },
  computed: {
    cancelingPolicies() {
      return this.$store.state.toSchedule.cancelingPolicies;
    },
    paymentMethod() {
      return this.$store.state.toSchedule.schedule.paymentMethod;
    },
  },
  created() {
    let paymentMethod = this.$store.state.toSchedule.schedule.paymentMethod;
    if(!paymentMethod?.is_credit_card && !paymentMethod?.is_pix) {
      this.$router.push('/payment');
    }
  },
  mounted() {
    this.$store.dispatch('toSchedule/getCancelingPolicies');
  },
  methods: {
    selectCancelingPolicy(cancelingPolicy) {
      this.$store.commit('toSchedule/selectCancelingPolicy', cancelingPolicy);
      this.$router.push('/payment');
    },
  }
}
</script>

<style scoped>

</style>
