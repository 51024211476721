import Config from "../config";

export default class {

    static isUrl(string) {
        try { return Boolean(new URL(string)); }
        catch(e){ return false; }
    }

    static url(path) {
        if (this.isUrl(path))
            return path;

        let baseUrl = Config.base_url;
        return `${baseUrl}${path}`;
    }

    static initialsName(name) {
        if (!name)
            return '';

        let firstLatter = name.substr(0, 1);

        let wordsName = name.split(" ");
        let lastLatter = wordsName[wordsName.length-1].substr(0, 1);

        if (wordsName.length === 1)
            lastLatter = name.substr(1, 1);

        return firstLatter+lastLatter;
    };

}