import Login from "../views/Login";
import Register from "../views/Register";
import ResetPassword from "../views/ResetPassword";

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            auth: false,
            layout: 'guest-layout'
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {
            auth: false,
            layout: 'guest-layout'
        }
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            auth: false,
            layout: 'guest-layout',
        }
    },
];

export default routes