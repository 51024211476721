<template>
    <div>
        <section class="bg-half-170 d-table w-100">
            <div class="container">
                <div class="row mt-5 align-items-center">
                    <div class="col-lg-6 col-md-7">
                        <div class="title-heading">
                            <h1 class="heading mb-3">Confirmação de <span class="text-primary">e-mail.</span></h1>

                            <div class="mt-3">
                                <router-link to="/schedules" class="btn btn-primary mt-2 me-2">
                                    Acessar minha conta
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div class="text-md-end text-center ms-lg-4">
                            <img src="../../assets/images/logo_ubbi.png" class="img-fluid" alt="app Ubbimed">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import http from "@/helpers/http";
import Alert from "@/helpers/alert";
import Swal from "sweetalert2";

export default {
    name: "EmailVerify",
    data() {
        return {
            state: 'loading',
            token: null,
            hash: null,
            userId: null,
        }
    },
    created() {
        this.token = this.$route.query.token;
        this.userId = this.$route.query.id;
        this.hash = this.$route.query.hash;
        this.authUsingToken();
    },
    mounted() {
        Swal.fire({
            title: 'Por favor, aguarde!',
            text: 'Confirmando e-mail',
            allowOutsideClick: false,
        });
        Swal.showLoading();
    },
    methods: {
        authUsingToken() {
            this.$store.dispatch('auth/loginUsingToken', this.token)
                .then(() => this.verifyEmail())
                .catch(() => Alert.error('Falha ao logar para confirmar e-mail'))
                .finally(() => this.state = 'loaded');
        },
        verifyEmail() {
            return http.get(`/users/${this.userId}/email/verify/${this.hash}`)
                .then(() => {
                    Swal.fire({
                        title: 'Aeeeh!',
                        text: 'E-mail confirmado com sucesso.',
                        icon: 'success',
                        allowOutsideClick: false,
                        confirmButtonText: 'Acessar minha conta',
                        confirmButtonColor: '#780f5a',
                    }).then(() => this.$router.push('/schedules'));
                })
                .catch(() => Alert.error('Falha ao confirmar e-mail'));
        },
    }
}
</script>

<style scoped>

</style>