import PatientSchedules from "../views/patient/Home.vue";
import PatientReSchedule from "../views/patient/ReSchedule.vue";
import PatientProfile from "../views/patient/Profile";
import PatientPatient from "../views/patient/Patient";
import PatientExams from "../views/patient/Exam";
import PatientPrescriptions from "../views/patient/Prescription";
import PatientDocuments from "../views/patient/Document";
import PatientQueue from "../views/patient/Queue";
import PatientScheduleAttendance from "../views/patient/Attendance.vue";
import EmailVerify from "../views/patient/EmailVerify.vue";

const routes = [
    {
        path: '/schedules',
        name: 'PatientSchedules',
        component: PatientSchedules,
        meta: {
            auth: true,
            layout: 'patient-layout'
        }
    },
    {
        path: '/schedules/reschedule',
        name: 'PatientReSchedule',
        component: PatientReSchedule,
        meta: {
            auth: false,
            layout: 'patient-layout'
        }
    },
    {
        path: '/exams',
        name: 'PatientExams',
        component: PatientExams,
        meta: {
            auth: true,
            layout: 'patient-layout'
        }
    },
    {
        path: '/prescriptions',
        name: 'PatientPrescriptions',
        component: PatientPrescriptions,
        meta: {
            auth: true,
            layout: 'patient-layout'
        }
    },
    {
        path: '/documents',
        name: 'PatientDocuments',
        component: PatientDocuments,
        meta: {
            auth: true,
            layout: 'patient-layout'
        }
    },
    {
        path: '/schedules/:scheduleId/queues/:queueId',
        name: 'PatientQueue',
        component: PatientQueue,
        meta: {
            auth: true,
            layout: 'patient-layout'
        }
    },
    {
        path: '/schedules/:scheduleId/attendance',
        name: 'PatientScheduleAttendance',
        component: PatientScheduleAttendance,
        meta: {
            auth: false,
            layout: 'none-layout'
        }
    },
    {
        path: '/patients',
        name: 'PatientPatient',
        component: PatientPatient,
        meta: {
            auth: true,
            layout: 'patient-layout'
        }
    },
    {
        path: '/profile',
        name: 'PatientProfile',
        component: PatientProfile,
        meta: {
            auth: true,
            layout: 'patient-layout'
        }
    },
    {
        path: '/email/verify',
        name: 'EmailVerify',
        component: EmailVerify,
        meta: {
            auth: false,
            layout: 'none-layout'
        }
    },
];

export default routes