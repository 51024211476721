<template>
  <section class="section-two bg-light mt-5 mb-2">
    <div class="container-fluid">
      <div class="row m-3">

        <div class="col-lg-8 offset-lg-2 order-1 order-sm-2 order-md-2">
          <div class="mb-4 ">
            <div class="form-icon position-relative">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-search fea icon-sm icons">
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
              <input name="insurance" id="insurance" class="form-control ps-5" placeholder="Pesquisar convênio" v-model="search">
            </div>
          </div>

          <div class="card shadow rounded border-0 mb-3 pricing-rates business-rate" @click="selectInsurance(insurance)"
               style="cursor: pointer;" v-for="insurance in insurances">
            <div class="text-center m-3">
              <b class="h6 text-primary">{{insurance.insurance_name}}</b>
              <i class="ms-1 fas fa-1x fa-arrow-circle-right text-primary"></i>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      search: '',
    }
  },
  computed: {
    insurances() {
      let insurances = this.$store.state.toSchedule.insurances;
      if (this.search === '')
        return insurances;

      return insurances.filter(insurance => {
        let strFilter = JSON.stringify(insurance);
        return strFilter.toLowerCase().includes(this.search.toLowerCase())
      });
    },
    professional() {
      return this.$store.state.toSchedule.schedule.professional;
    },
  },
  mounted() {
    this.$store.dispatch('toSchedule/getInsurances');
  },
  methods: {
    selectInsurance(insurance) {
      this.$store.commit('toSchedule/selectInsurance', insurance);
      if (this.$store.getters['auth/isAuth']) {
        this.$router.push('/schedule/patients');
      } else {
        this.$router.push('/schedule/login');
      }
    },
  }
}
</script>

<style scoped>

</style>
