<script>
export default {
  name: "SpecialtiesModal",
  props: ['specialties'],
}
</script>

<template>
  <div class="modal fade" id="ModalSpecialties" tabindex="-1" aria-labelledby="ModalSpecialties" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded shadow border-0">
        <div class="modal-header border-bottom">
          <h5 class="modal-title">Especialidades</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="bg-white p-3 rounded box-shadow">
            <ul class="list-unstyled text-muted">

              <li class="mb-0" v-for="specialty in specialties">
                  <span class="text-primary h5 me-2">
                    <i class="uil uil-check-circle align-middle"></i>
                  </span> {{ specialty.spec_name }}
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>