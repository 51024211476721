import { createRouter, createWebHistory } from 'vue-router';
import db from "../helpers/db";

import auth from './auth';
import patient from './patient';
import toSchedule from './toSchedule';

const routes = [
    ...auth,
    ...patient,
    ...toSchedule,
    {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
    },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        if (db.token == null || db.token === 'null') {
            next({path: '/login', params: {nextUrl: to.fullPath}});
        } else {
            next();
        }
    } else {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);

        next();
    }
});

export default router
