<template>
    <form id="form-checkout" method="post">
        <input type="hidden" id="deviceId">
        <select id="form-checkout__identificationType" style="display:none;"></select>
        <select id="form-checkout__installments" style="display:none;"></select>
        <input type="text" id="form-checkout__identificationNumber" style="display:none;" :value="userAuth.user_cpf"/>
        <input type="text" id="form-checkout__cardholderEmail" style="display:none;" :value="userAuth.user_email"/>

        <div class="mb-3">

            <div class="card rounded border-0 shadow">
                <div class="bg-light p-4 text-center rounded-top">
                    <h4 class="mb-0 card-title text-primary">Cartão de crédito</h4>
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="mb-3">
                                <label class="form-label" for="form-checkout__cardholderName">Nome: <span
                                    class="text-danger">*</span></label>
                                <input name="name" id="form-checkout__cardholderName" type="text"
                                       class="form-control fw-bold" required=""
                                       placeholder="Ex. João S Farias">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="mb-3">
                                <label class="form-label" for="credit_card_number">
                                    Número do cartão: <span class="text-danger">*</span>
                                </label>
                                <input type="text" autocomplete="off" id="form-checkout__cardNumber"
                                       class="form-control fw-bold" v-mask="'#### #### #### ####'"
                                       required="" placeholder="0000 0000 0000 0000">
                            </div>
                        </div>
                        <div class="col-md-12" style="display: none">
                            <div class="mb-3">
                                <label class="form-label" for="form-checkout__issuer">
                                    Emissor: <span class="text-danger">*</span>
                                </label>
                                <select type="text" id="form-checkout__issuer"
                                        class="form-control fw-bold">
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label" for="credit_card_data_valid">Data de expiração: <span
                                    class="text-danger">*</span></label>
                                <input type="text" autocomplete="off" id="form-checkout__expirationDate"
                                       class="form-control fw-bold"
                                       required="" placeholder="MM/AAAA" v-mask="'##/####'">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label" for="credit_card_cvv">Código de segurança: <span
                                    class="text-danger">*</span></label>
                                <input type="text" autocomplete="off" id="form-checkout__securityCode"
                                       class="form-control fw-bold"
                                       required="" placeholder="CVV" v-mask="'###'">
                            </div>
                        </div>
                        <div class="col-md-12" v-if="schedule.availabilityTime.avail_online">
                            <br>
                            <div class="mb-3">
                                <div class="form-check">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Ao agendar você concorda com
                                        <a target="_blank" href="https://policies.ubbimed.com.br/term-telemedicine.php"
                                           class="text-primary">
                                            Termos da teleconsulta.
                                        </a>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="d-grid">
                                <button class="btn btn-primary" type="submit" id="form-checkout__submit">
                                    Agendar consulta
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </form>
</template>

<script>
import {mask} from "vue-the-mask";
import http from "../../../helpers/http";

export default {
    name: "CreditCardMP",
    directives: {
        mask,
    },
    data() {
        return {
            msgErrorHashCreditCard: '',
        }
    },
    created() {
        let script = 'https://sdk.mercadopago.com/js/v2';
        let tag = document.head.querySelector(`[src="${script}"`);
        tag = document.createElement("script");
        tag.setAttribute("src", script);
        tag.setAttribute("type", 'text/javascript');
        document.head.appendChild(tag);

        let scriptSecurity = 'https://www.mercadopago.com/v2/security.js';
        let tagSecurity = document.head.querySelector(`[src="${scriptSecurity}"`);
        tagSecurity = document.createElement("script");
        tagSecurity.setAttribute("src", scriptSecurity);
        tagSecurity.setAttribute("type", 'text/javascript');
        tagSecurity.setAttribute("view", 'checkout');
        tagSecurity.setAttribute("output", 'deviceId');
        document.head.appendChild(tagSecurity);

    },
    async mounted() {
        let response = await http.get('/card-administrators/MP/public-key', {
            params: {
                id_place: this.schedule.professional.id_place,
            }
        });
        let publicKey = response.data;

        await new Promise(r => setTimeout(r, 1000));
        const mp = new MercadoPago(publicKey);

        const cardForm = mp.cardForm({
            locale: 'pt-BR',
            amount: this.schedule.cancelingPolicy.value,
            iframe: false,
            form: {
                id: "form-checkout",
                cardNumber: {
                    id: "form-checkout__cardNumber",
                    placeholder: "Número do cartão",
                },
                expirationDate: {
                    id: "form-checkout__expirationDate",
                    placeholder: "MM/YY",
                },
                securityCode: {
                    id: "form-checkout__securityCode",
                    placeholder: "Código de segurança",
                },
                cardholderName: {
                    id: "form-checkout__cardholderName",
                    placeholder: "Titular do cartão",
                },
                issuer: {
                    id: "form-checkout__issuer",
                    placeholder: "Banco emissor",
                },
                installments: {
                    id: "form-checkout__installments",
                    placeholder: "Parcelas",
                },
                identificationType: {
                    id: "form-checkout__identificationType",
                    placeholder: "Tipo de documento",
                },
                identificationNumber: {
                    id: "form-checkout__identificationNumber",
                    placeholder: "Número do documento",
                },
                cardholderEmail: {
                    id: "form-checkout__cardholderEmail",
                    placeholder: "E-mail",
                },
            },
            callbacks: {
                onFormMounted: error => {
                    if (error) return console.warn('Form Mounted handling error: ', error)
                },
                onSubmit: (event) => {
                    event.preventDefault();
                    const cardFormData = cardForm.getCardFormData();

                    this.$emit('onSubmit', {
                        issuer_id: cardFormData.issuerId,
                        payment_method_id: cardFormData.paymentMethodId,
                        token: cardFormData.token,
                        device_id: document.getElementById('deviceId')?.value,
                    });
                },
            },
        });
    },
    computed: {
        paymentMethod() {
            return this.$store.state.toSchedule.schedule.paymentMethod;
        },
        schedule() {
            return this.$store.state.toSchedule.schedule;
        },
        userAuth() {
            return this.$store.state.auth.user;
        },
    },
}
</script>

<style scoped>

</style>