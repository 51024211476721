<template>
  <div class="card shadow rounded border-0 pb-5 pt-5 mb-3">
    <div class="text-center m-3">
      <div class="icon text-center rounded-circle me-3">
        <i class="fas fa-5x fa-ellipsis-h"></i>
      </div>
      <h4 class="title mb-0">
        Nenhuma informação encontrada.
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoResult",

}
</script>

<style scoped>

</style>