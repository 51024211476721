<template>
  <section class="section-two bg-light mt-5 mb-2">
    <div class="container-fluid">
      <div class="row m-3">

        <div class="col-lg-3 mb-4 order-2 order-sm-1 order-md-1">
          <MenuAction/>
        </div>

        <div class="col-lg-9 order-1 order-sm-2 order-md-2">
            <p class="h4">
                <b>Escolha o convênio</b>
            </p>

          <div class="mb-4">
            <div class="form-icon position-relative">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-search fea icon-sm icons">
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
              <input name="insurance" id="insurance" class="form-control ps-5" placeholder="Pesquisar convênio" v-model="search">
            </div>
          </div>

          <div class="card shadow rounded border-0 mb-3 pricing-rates business-rate" @click="selectInsurance(insurance)"
               style="cursor: pointer;" v-for="insurance in insurances">
            <div class="text-center m-3">
              <b class="h6 text-primary">{{insurance.nick_name ?? insurance.trading_name ?? insurance.insurance_name}}</b>
              <i class="ms-1 fas fa-1x fa-arrow-circle-right text-primary"></i>
            </div>
          </div>

          <div class="card shadow text-center rounded border-0 mb-3 p-4" v-if="insurances.length <= 0">
            <img :src="require('@/assets/images/no_datal.svg')"
                 alt="Foto" class="w-25 align-self-center"/>

            <p class="h4 mt-3">Opss... Nenhum convênio encontrado.</p>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import MenuAction from './MenuAction.vue'

export default {
  name: 'Insurance',
  components: {
    MenuAction
  },
  data() {
    return {
      search: '',
    }
  },
  computed: {
    insurances() {
      let insurances = this.$store.state.toSchedule.insurances;
      if (this.search === '')
        return insurances;

      return insurances.filter(insurance => {
        let strFilter = JSON.stringify(insurance);
        return strFilter.toLowerCase().includes(this.search.toLowerCase())
      });
    },
  },
  mounted() {
    this.$store.dispatch('toSchedule/getInsurances');
  },
  methods: {
    selectInsurance(insurance) {
      this.$store.commit('toSchedule/selectInsurance', insurance);
      this.$router.push('/professionals');
    },
  }
}
</script>

<style scoped>

</style>
