import Alert from "./alert";

export default class Download {
    static base64File(contentType, base64Data, fileName) {
        if(!base64Data)
            return Alert.error('Não é possível realizado o download. Contéudo do arquivo nulo.');

        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };
}