<template>
  <div class="card shadow rounded border-0 d-none d-sm-block">
    <div class="card-body">
      <ul class="list-unstyled text-muted list-unstyled-scroll">

          <li class="text-end d-none d-lg-block" style="margin-bottom: -15px">
              <button type="button" class="btn btn-outline-primary btn-sm"
                      @click="$route.path === '/schedule/patients' ? $router.go(-3) : $router.back()">
                  <i class="fa fa-arrow-left"></i> Voltar
              </button>
          </li>

        <li class="mb-0" v-if="schedule.city">
          <i class="fas fa-map-marker-alt"></i> Cidade <br>
          <router-link :to="disabled ? '' : '/cities'" :title="disabled ? '' : 'Clique para alterar'" data-bs-toggle="tooltip">
                <span class="text-primary h5 me-2">
                <i class="uil uil-check-circle align-middle"></i>
              </span> {{schedule.city.city_name}} - {{schedule.city.state_initials}}
          </router-link>
          <hr>
        </li>

        <li class="mb-0" v-if="schedule.specialty">
          <i class="fas fa-user-tag"></i> Especialidade <br>
          <router-link :to="disabled ? '' : '/specialties'" :title="disabled ? '' : 'Clique para alterar'" data-bs-toggle="tooltip">
                <span class="text-primary h5 me-2">
                <i class="uil uil-check-circle align-middle"></i>
              </span> {{schedule.specialty.spec_name}}
          </router-link>
          <hr>
        </li>

        <li class="mb-0" v-if="schedule.paymentMethod">
          <i class="far fa-handshake"></i> Forma de pagamento <br>
          <router-link :to="disabled ? '' : '/payment-methods'" :title="disabled ? '' : 'Clique para alterar'" data-bs-toggle="tooltip">
                <span class="text-primary h5 me-2">
                <i class="uil uil-check-circle align-middle"></i>
              </span> {{schedule.paymentMethod.paymeth_name}}
          </router-link>
          <hr>
        </li>

        <li class="mb-0" v-if="schedule.insurance">
          <i class="fas fa-hospital-user"></i> Convênio <br>
          <router-link :to="disabled ? '' : '/insurances'" :title="disabled ? '' : 'Clique para alterar'" data-bs-toggle="tooltip">
                <span class="text-primary h5 me-2">
                <i class="uil uil-check-circle align-middle"></i>
              </span> {{schedule.insurance.insurance_name}}
          </router-link>
          <hr>
        </li>

        <li class="mb-0" v-if="schedule.professional">
          <i class="fas fa-user-md"></i> Profissional <br>
          <router-link :to="disabled ? '' : '/professionals'" :title="disabled ? '' : 'Clique para alterar'" data-bs-toggle="tooltip">
                <span class="text-primary h5 me-2">
                <i class="uil uil-check-circle align-middle"></i>
              </span> {{schedule.professional.prof_name}}
          </router-link> <br>
          <span class="badge bg-primary text-start">{{schedule.professional.place_name}}</span>
          <hr>
        </li>

        <li class="mb-0" v-if="schedule.availabilityTime">
          <i class="fas fa-calendar-day"></i> Agendamento <br>
          <router-link :to="disabled ? '' : '/professionals'" :title="disabled ? '' : 'Clique para alterar'" data-bs-toggle="tooltip">
                <span class="text-primary h5 me-2">
                <i class="uil uil-check-circle align-middle"></i>
              </span>
            {{date(schedule.availabilityTime.available_date_patient).format('DD MMM YYYY - dddd')}}
            <span class="badge bg-primary"> {{schedule.availabilityTime.available_time_patient}}h </span> <br>
            <span v-if="schedule.availabilityTime.avail_online" class="badge bg-primary"> Online </span>
          </router-link>
          <hr>
        </li>

        <li class="mb-0" v-if="schedule.patient">
          <i class="fas fa-user-injured"></i> Paciente <br>
          <router-link :to="disabled ? '' : '/schedule/patients'" :title="disabled ? '' : 'Clique para alterar'" data-bs-toggle="tooltip">
                <span class="text-primary h5 me-2">
                <i class="uil uil-check-circle align-middle"></i>
              </span>
            {{schedule.patient.patient_name}}
          </router-link>
          <hr>
        </li>

        <li class="mb-0" v-if="schedule.cancelingPolicy">
          <i class="fas fa-balance-scale-right"></i> Política de cancelamento <br>
          <router-link :to="disabled ? '' : '/canceling-policies'" :title="disabled ? '' : 'Clique para alterar'" data-bs-toggle="tooltip">
              <span class="text-primary h5 me-2">
                <i class="uil uil-check-circle align-middle"></i>
              </span>
            {{schedule.cancelingPolicy.policy_cancel_name}}
          </router-link>
          <hr>
        </li>

        <li class="mb-0" v-if="schedule.cancelingPolicy">
          <i class="fas fa-check-double"></i> Confirmar <br>
          <router-link :to="disabled ? '' : '/payment'" :disabled="disabled">
            <span class="text-primary h5 me-2">
              <i class="uil uil-check-circle align-middle"></i>
            </span>
            Confirme seus dados
          </router-link>
          <hr>
        </li>

      </ul>
    </div>
  </div>
</template>

<script>
import date from "../../helpers/date";

export default {
  name: "MenuAction",
    props: {
        disabled: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    computed: {
      schedule() {
          return this.$store.state.toSchedule.schedule;
      },
    },
  methods: {
    date: date,
  }
}
</script>

<style scoped>

</style>