<template>
  <section class="section-two bg-light mt-5 mb-2">
    <div class="container-fluid">

      <div class="row m-3">

        <div v-if="canShowMenuAction" class="col-lg-3 mb-4 order-2 order-sm-1 order-md-1">
          <MenuAction/>
        </div>

        <div :class="canShowMenuAction ? 'col-lg-9 order-1 order-sm-2 order-md-2' : 'col-lg-12 order-1 order-sm-2 order-md-2'">

            <p class="h4">
                <b>Escolha a cidade</b>
            </p>

          <div class="mb-4">
            <div class="form-icon position-relative">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-search fea icon-sm icons">
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
              <input name="city" id="city" class="form-control ps-5" placeholder="Pesquisar cidade" v-model="search">
            </div>
          </div>

          <div class="card shadow rounded border-0 mb-3 pricing-rates business-rate" @click="selectCity(city)"
               style="cursor: pointer;" v-for="city in cities" :title="'Agendar em ' + city.city_name">
              <div class="text-center m-3">
                <i class="fas fa-2x fa-map-marker-alt text-primary"></i> <br>
                <b class="h5 text-primary">{{city.city_name}} - {{city.state_initials}}</b>
              </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MenuAction from './MenuAction.vue'

export default {
  name: 'CitiesSupported',
  components: {
    MenuAction
  },
  data() {
    return {
      search: '',
    }
  },
  computed: {
    cities() {
      let cities = this.$store.state.toSchedule.cities;
      if (this.search === '')
        return cities;

      return cities.filter(city => {
        let strFilter = JSON.stringify(city);
        return strFilter.toLowerCase().includes(this.search.toLowerCase())
      });
    },

    canShowMenuAction() {
      let scheduleData = this.$store.state.toSchedule.schedule;
      return scheduleData.city != null ||
             scheduleData.cancelingPolicy != null ||
             scheduleData.specialty != null ||
             scheduleData.patient != null;
    }
  },
  mounted() {
    this.$store.dispatch('toSchedule/getCitiesSupported');
  },
  methods: {
    selectCity(city) {
      this.$store.commit('toSchedule/selectCity', city);
      this.$router.push('/specialties')
    },

  }
}
</script>

<style scoped>

</style>
