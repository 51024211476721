<template>
  <div class="card border-0 rounded shadow">
    <div class="card-body">
      <h5 class="text-md-start text-center">Meus dados</h5>
      <div class="mt-3 text-md-start text-center d-sm-flex">
        <div class="row">
          <div class="col-6">
            <img :src="url(userAuth.user_photo)" v-if="userAuth.user_photo"
                 class="avatar float-md-left avatar-medium rounded-circle shadow me-md-4" alt="Minha foto">
            <p style="line-height: 1.7" class="avatar text-center avatar-medium rounded-circle shadow me-md-4" v-else>
              <span style="font-size: 65px">
                {{initialsName()}}
              </span>
            </p>
          </div>
          <div class="col-6">
            <div class="mt-md-4 mt-3 mt-sm-0">
              <button type="button" @click="removePhoto" class="btn btn-outline-primary mt-2 ms-2">Remover</button>
            </div>
          </div>
        </div>
      </div>
      <form method="post" @submit.prevent="updateUser">
        <div class="row mt-4">
          <div class="col-md-12">
            <div class="alert alert-success" role="alert" v-if="msgSuccess">
              {{msgSuccess}}
            </div>

            <div class="alert alert-danger" role="alert" v-if="msgError">
              {{msgError}}
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">Nome</label>
              <div class="form-icon position-relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                <input name="name" id="first" type="text" class="form-control ps-5" placeholder="Seu nome :"
                       v-model="userForm.user_name" :class="errors.user_name ? 'is-invalid' : ''">
                <div class="invalid-feedback d-block" v-if="errors.user_name">
                  {{ errors.user_name.join('\n') }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">E-mail</label>
              <div class="form-icon position-relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail fea icon-sm icons"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                <input name="email" id="email" disabled type="email" class="form-control ps-5"
                       placeholder="Seu email :" style="text-transform: lowercase;"
                       v-model="userForm.user_email" :class="errors.user_email ? 'is-invalid' : ''">
                <div class="invalid-feedback d-block" v-if="errors.user_email">
                  {{ errors.user_email.join('\n') }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">Telefone</label>
              <div class="form-icon position-relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone fea icon-sm icons"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                <input name="name" id="last" type="text" class="form-control ps-5" placeholder="Seu telefone :"
                       v-model="userForm.user_phone" v-mask="['(##) ####-####', '(##) #####-####']"
                       :class="errors.user_phone ? 'is-invalid' : ''">
                <div class="invalid-feedback d-block" v-if="errors.user_phone">
                  {{ errors.user_phone.join('\n') }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">CPF</label>
              <div class="form-icon position-relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-square fea icon-sm icons"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>
                <input name="cpf" id="cpf" type="tel" class="form-control ps-5" placeholder="Seu cpf :"
                       v-model="userForm.user_cpf" v-mask="'###.###.###-##'" :class="errors.user_cpf ? 'is-invalid' : ''">
                <div class="invalid-feedback d-block" v-if="errors.user_cpf">
                  {{ errors.user_cpf.join('\n') }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">Data Nascimento</label>
              <div class="form-icon position-relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar fea icon-sm icons"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                <input name="data_nasc" id="data_nasc" type="tel" class="form-control ps-5" v-mask="'##/##/####'"
                       placeholder="Sua data de nascimento :" required
                       v-model="userForm.user_birthdate" :class="errors.user_birthdate ? 'is-invalid' : ''">
                <div class="invalid-feedback d-block" v-if="errors.user_birthdate">
                  {{ errors.user_birthdate.join('\n') }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">Foto</label>
              <div class="form-icon position-relative">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-camera fea icon-sm icons"><path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path><circle cx="12" cy="13" r="4"></circle></svg>
                <input name="photo" id="photo" type="file" class="form-control ps-5" placeholder="Sua foto :"
                       accept="image/*" :class="errors.user_photo ? 'is-invalid' : ''" ref="photo">
                <div class="invalid-feedback d-block" v-if="errors.user_photo">
                  {{ errors.user_photo.join('\n') }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 mt-2 mb-0">
            <button type="submit" class="btn btn-primary">Salvar</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import str from "@/helpers/str";
import http from "@/helpers/http";
import date from "@/helpers/date";
import Alert from "@/helpers/alert";
import {mask} from "vue-the-mask";

export default {
  name: "Profile",
  directives: {
    mask,
  },
  data() {
    return {
      msgError: '',
      msgSuccess: '',
      errors: [],
      userForm: {
        user_name: '',
        user_email: '',
        user_cpf: '',
        user_phone: '',
        user_birthdate: '',
        user_photo: '',
      }
    }
  },
  watch: {
    'userForm.user_birthdate': {
      handler(val) {
        if (val?.length === 10) {
          let date = this.date(val, 'DD/MM/YYYY');
          if (date.isAfter(new Date())) {
            Alert.error('Data de nascimento não pode ser maior que hoje.');
            this.userForm.user_birthdate = '';
          }
        }
      },
      deep: true
    }
  },
  computed: {
    userAuth() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    this.findUser();
  },
  methods: {
    date: date,
      url(path) {
          return str.url(path)
      },
    findUser() {
      http.get(`/users/me`).then((response) => {
        this.userForm = response.data;
        this.userForm.user_birthdate = this.userForm.user_birthdate_br;
      });
    },
    initialsName() {
      return str.initialsName(this.userAuth.user_name);
    },
    removePhoto() {
      http.delete(`/users/${this.userAuth.id_user}/photo`).then((response) => {
        Alert.success('Foto removida com sucesso.');
        this.$store.commit('auth/setUser', response.data);
      });
    },
    updateUser() {
      this.msgSuccess = '';
      this.msgError = '';

      const form = new FormData();
      form.append('_method', 'put');
      form.append('user_name', this.userForm.user_name);
      form.append('user_cpf', this.userForm.user_cpf);
      form.append('user_phone', this.userForm.user_phone);
      form.append('user_birthdate', this.userForm.user_birthdate);

      if (this.$refs.photo.files.length > 0)
        form.append('user_photo', this.$refs.photo.files[0]);

      http.post(`/users/${this.userAuth.id_user}/profile`, form).then((response) => {
        this.msgSuccess = 'Dados atualizados com sucesso.';
        this.$store.commit('auth/setUser', response.data);
      }).catch((error) => {
        let response = error.response;
        if (response.data?.errors) {
          this.errors = response.data.errors;
        } else {
          this.msgError = response.data;
        }
      }).finally(() => {
        this.submitted = false;
      });
    }
  }
}
</script>

<style scoped>

</style>