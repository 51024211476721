import Echo from 'laravel-echo';
import Config from "./config";
import Pusher from 'pusher-js';
import db from "./helpers/db";
window.Pusher = Pusher;

window.Echo = new Echo({
    authEndpoint: Config.base_url + '/broadcasting/auth',
    auth: {
        headers: {
            'Authorization': 'Bearer ' + db.token,
            'ubbi-app': Config.app_name,
            'ubbi-app-token': Config.app_token,
            'ubbi-timezone': Config.timezone,
        }
    },
    broadcaster: 'reverb',
    key: process.env.VUE_APP_REVERB_APP_KEY,
    wsHost: process.env.VUE_APP_REVERB_HOST,
    wsPort: process.env.VUE_APP_REVERB_PORT,
    wssPort: process.env.VUE_APP_REVERB_PORT,
    forceTLS: (process.env.VUE_APP_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
});