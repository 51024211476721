import { createStore } from 'vuex';
import toSchedule from './toSchedule'
import auth from "./auth";

export default createStore({
  state: {
    urlProfessional: process.env.VUE_APP_SITE_PROFESSIONAL ?? 'https://profissional.ubbimed.com.br'
  },
  mutations: {

  },
  actions: {

  },
  getters: {
    urlProfessional(state) {
      return state.urlProfessional;
    }
  },
  modules: {
    toSchedule: toSchedule,
    auth: auth,
  }
})
