<template>
  <section class="section-two bg-light mt-5 mb-2">
    <div class="container-fluid">
      <div class="row m-3">

        <div class="col-lg-3 mb-4 order-2 order-sm-1 order-md-1">
          <MenuAction/>
        </div>

        <div class="col-lg-9 order-1 order-sm-2 order-md-2">
            <p class="h4">
                <b>Escolha a forma de pagamento</b>
            </p>
          <div class="card shadow rounded border-0 mb-3 pricing-rates business-rate" @click="selectPaymentMethod(paymentMethod)"
               style="cursor: pointer;" v-for="paymentMethod in paymentMethods">
            <div class="m-3">
              <b class="h6 text-primary">{{paymentMethod.paymeth_name}}</b>
              <i class="ms-1 fas fa-1x fa-arrow-circle-right text-primary"></i>
              <p class="text-muted mb-0">{{paymentMethod.additional_information}}</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import MenuAction from './MenuAction.vue'

export default {
  name: 'PaymentMethod',
  components: {
    MenuAction
  },
  data() {
    return {
      search: '',
    }
  },
  computed: {
    paymentMethods() {
      let paymentMethods = this.$store.state.toSchedule.paymentMethods;
      if (this.search === '')
        return paymentMethods;

      return paymentMethods.filter(paymentMethod => {
        let strFilter = JSON.stringify(paymentMethod);
        return strFilter.toLowerCase().includes(this.search.toLowerCase())
      });
    },
  },
  mounted() {
    this.$store.dispatch('toSchedule/getPaymentMethods');
  },
  methods: {
    selectPaymentMethod(paymentMethod) {
      this.$store.commit('toSchedule/selectPaymentMethod', paymentMethod);
      if (paymentMethod.is_insurance) {
        this.$router.push('/insurances');
      } else {
        this.$router.push('/professionals');
      }
    },

  }
}
</script>

<style scoped>

</style>
