<template>
  <div class="row">
    <div @click="logInWithFacebook" class="btn btn-light me-1">
      <i class="mdi mdi-facebook text-primary"></i>
      Facebook
    </div>
  </div>
</template>

<script>
export default {
  name: "BtnLoginFacebook",
  mounted() {

  },
  methods: {
    async logInWithFacebook() {
      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();
      setTimeout(() => {
        window.FB.login((response) => {
          if (response.authResponse) {
            this.$emit('onSuccess', response.authResponse);
          } else {
            this.$emit('onFailure', "User cancelled login or did not fully authorize.");
          }
        });
      }, 1000);

      return false;
    },
    async initFacebook() {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: "304653250963436", //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: "v12.0"
        });
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }
  }
}
</script>

<style scoped>

</style>